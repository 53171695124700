import React, { Component } from "react";
import ReactTable from "react-table";
import apiClient from '../../components/Core/apiClient';
import Swal from "sweetalert2";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import DropDownNoAdd from "../../components/Form/DropDownNoAdd";
import SVGIcon from "../../components/Core/Icons/SVGIcons";
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


class Request extends Component {
  constructor(props) {
    super(props);
    require("react-table/react-table.css");
    require("react-notifications/lib/notifications.css");
    require("../../containers/reactTable.scss");
    require("antd/dist/antd.css");
    require("./Item.scss");
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: null,
      searchValue: '',
      companies: null,
      selectedCompany: 0,
      total: 0,
      isAdmin: localStorage.getItem("roles").includes("Admin"),
    };
    this.fetchItems = this.fetchItems.bind(this);
    this.fetchCompanies = this.fetchCompanies.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleRequestCreate = this.handleRequestCreate.bind(this);
  };

  componentDidMount() {
    this.fetchCompanies();
  }

  async fetchItems(state) {
    let endpoint = "api/Business/ItemList";
    this.setState({
      loading: true
    });
    if (this.state.isAdmin) {
      await apiClient.post(endpoint, {
        "businessId": this.state.selectedCompany,
        "searchValue": this.state.searchValue === null ? "" : this.state.searchValue,
        "lastRowId": 0,
        "sortColumn": state.sorted === undefined || state.sorted.length === 0 ? "CreatedDate" : state.sorted[0]['id'],
        "isDescending": state.sorted === undefined || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
        "perPage": state.pageSize === undefined ? 10 : state.pageSize,
        "skip": state.page * state.pageSize
      }).then(response => {
        this.setState({
          data: response.data["entities"],
          pages: Math.ceil(parseInt(response.data["total"]) / state.pageSize),
          loading: false,
          total: response.data["total"],
          tableState: state,
          lastRowId: response.data["lastRowId"]
        });

      }).catch(error => {
        console.error(error);
        this.setState({
          loading: false
        });
        console.error(`Axios request failed: ${error}`);
      });
    } else {
      await apiClient.post(endpoint, {
        "searchValue": this.state.searchValue === null ? "" : this.state.searchValue,
        "lastRowId": 0,
        "sortColumn": state.sorted === undefined || state.sorted.length === 0 ? "CreatedDate" : state.sorted[0]['id'],
        "isDescending": state.sorted === undefined || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
        "perPage": state.pageSize === undefined ? 10 : state.pageSize,
        "skip": state.page * state.pageSize
      }).then(response => {
        this.setState({
          data: response.data["entities"],
          pages: Math.ceil(parseInt(response.data["total"]) / state.pageSize),
          loading: false,
          total: response.data["total"],
          tableState: state,
          lastRowId: response.data["lastRowId"]
        });

      }).catch(error => {
        console.error(error);
        this.setState({
          loading: false
        });
        console.error(`Axios request failed: ${error}`);
      });
    }

  }

  async fetchCompanies() {
    let endpoint = "api/Business/List";
    await apiClient.post(endpoint,
      {
        "searchValue": "",
        "lastRowId": 0,
        "sortColumn": "CreatedDate",
        "isDescending": true,
        "perPage": 1000
      }
    )
      .then(async response => {
        await this.setState({
          companies: this.mapCompanies(response.data["entities"])
        });
      }).catch(error => {
        console.error(`Axios request failed: ${error}`);
      });
  }

  mapCompanies(companies) {
    let res = [];
    if (!companies || !companies.length)
      return res;

    for (let i = 0; i < companies.length; i++) {
      res.push({ value: companies[i]["id"], label: companies[i]["name"] });
    }
    return res;
  }

  onRowClick = (action, row, column) => {
    if (column.Header !== "Actions") {
      this.props.isAdmin === undefined ? this.props.history.push('/admin/users/edit/' + row.id) : this.props[0].history.push('/users/edit/' + row.id);
    }
  };

  onRowClick = (action, row, column) => {
    if (column.Header !== "Actions")
      this.props.history.push('/items/edit/' + row.id);
  };

  deleteItem(id) {
    Swal.fire({
      // title: 'Are you sure?',
      text: "Are you sure that you want to delete this request?",
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#c9d1d8',
      confirmButtonColor: '#666FE8',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let endpoint = 'api/Business/DeclineItem/' + id;
        apiClient.delete(endpoint).then(response => {
          this.fetchItems(this.state.tableState);
          NotificationManager.success('Request has been successfully deleted!', 'Success');
        }).catch(error => {
          NotificationManager.error(`${error}`, 'Error');
          console.error(`Axios request failed: ${error}`);
          Swal.close();
        });;
      }
    })
  }

  async handleCompanyChange(selected) {
    await this.setState({
      selectedCompany: selected === null ? 0 : selected.value,
      loading: true
    },
    )
    this.fetchItems(this.state.tableState);
  }

  async onSearch(e) {
    await this.setState({ searchValue: e.target.value });
    this.fetchItems(this.state.tableState);
  }

  handleRequestCreate() {
    this.props.history.push('/items/create')
  }

  render() {
    const { data, pages, loading } = this.state;
    var columns = [];
    if (this.state.isAdmin) {
      columns = [
        {
          Header: "Shortcode",
          id: "shortCode",
          accessor: d => d.shortCode
        },
        {
          Header: "Territory",
          id: "isNationWide",
          accessor: d => d.isNationWide,
          Cell: props => [
            <span className="" key="nationwide">
              {props.value === true ?
                <i className="fa fa-check" aria-hidden="true" style={{ color: '#29b92d' }}></i>
                :
                ""}
            </span>
          ]
        },
        {
          Header: "Keyword",
          id: "keyword",
          accessor: d => d.keyword
        },
        {
          Header: "Company",
          id: "businessName",
          accessor: d => d.businessName
        },
        {
          Header: "Status",
          id: "status",
          accessor: d => d.status
        },
        {
          Header: 'Actions',
          style: { textAlign: "center" },
          id: "id",
          sortable: false,
          accessor: d => d.id,
          Cell: props => [
            <Link to={`/items/edit/` + props.value} className="btn" key="edit-item-" data-toggle="tooltip" title="Edit request" style={{ marginRight: '.6rem' }}>{SVGIcon.eye}</Link>,
            this.state.isAdmin ?
              <button onClick={() => this.deleteItem(props.value)} className="btn" key="delete-item" data-toggle="tooltip" title="Delete request">{SVGIcon.trash}</button>
              : ""
          ]
        }
      ];
    } else
      columns = [
        {
          Header: "Shortcode",
          id: "shortCode",
          accessor: d => d.shortCode
        },
        {
          Header: "Territory",
          id: "isNationWide",
          accessor: d => d.isNationWide,
          Cell: props => [
            <span className="" key="nationwide">
              {props.value === true ?
                <i className="fa fa-check" aria-hidden="true" style={{ color: '#29b92d' }}></i>
                :
                ""}
            </span>
          ]
        },
        {
          Header: "Keyword",
          id: "keyword",
          accessor: d => d.keyword
        },
        {
          Header: "Status",
          id: "status",
          accessor: d => d.status
        },
        {
          Header: 'Actions',
          style: { textAlign: "center" },
          id: "id",
          sortable: false,
          accessor: d => d.id,
          Cell: props => [
            <Link to={`/items/edit/` + props.value} className="btn" key="edit-item-" data-toggle="tooltip" title="Edit request" style={{ marginRight: '.6rem' }}>{SVGIcon.eye}</Link>,
            this.state.isAdmin ?
              <button onClick={() => this.deleteItem(props.value)} className="btn" key="delete-item" data-toggle="tooltip" title="Delete request">{SVGIcon.trash}</button>
              : ""
          ]
        }
      ];
    return (
      <>
        <div className="packages-wrapper">
          <div className="requests-header pb-2 mb-3">
            <div className="requests-header-title">
              <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Packages ({this.state.total})</h2>
            </div>
            <div className="requests-header-tools">
              <Button
                shape="round"
                onClick={this.handleRequestCreate}
                icon={<PlusOutlined />}
                style={{ marginRight: '1rem' }}
              >
                {this.state.isAdmin ? "Add new package" : "Add new request"}
              </Button>
              {this.state.isAdmin ?
                <div className="request-list-tools-business">
                  <DropDownNoAdd
                    onChange={this.handleCompanyChange}
                    options={this.state.companies}
                    isClearable={true}
                    placeholder="Choose a company"
                    isMulti={false} />
                </div>
                : " "}
              <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                <input type="text" className="form-control" placeholder="Search..." id="generalSearch" value={this.state.searchValue}
                  onChange={this.onSearch} />
                <span className="kt-input-icon__icon kt-input-icon__icon--right">
                  <span>
                    {SVGIcon.search}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="kt-portlet" style={{ borderRadius: "20px" }}>
            <div className="kt-portlet__body user-table-container">
              <ReactTable
                columns={columns}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={this.fetchItems} // Request new data when things change
                defaultPageSize={10}
                className="-striped -highlight"
                getTdProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (event, handleOriginal) => {
                        event.stopPropagation();
                        this.onRowClick("click", rowInfo.row._original, column);
                      }
                    };
                  }
                  return {};
                }}
              />
            </div>
          </div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

export default Request;