import React, { Component } from "react";
import path from 'path';

export default class LandingPageLayout extends Component {
  constructor(props) {
    super(props);
    require("../assets/appside");
    require('../site.scss')
  }
  componentDidMount() {

    // init metas
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1";
    document.head.appendChild(meta);

    // init styles
    let style = document.createElement("link");
    style.rel = "shortcut icon";
    style.type = "image/x-icon";
    // style.href = path.resolve(process.env.PUBLIC_URL, "img/favicon.ico");
    document.head.appendChild(style);

    style = document.createElement("link");
    style.rel = "stylesheet";
    style.href = "https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap";
    document.head.appendChild(style);

    style = document.createElement("link");
    style.rel = "stylesheet";
    style.href = "https://fonts.googleapis.com/css?family=Comfortaa&display=swap";
    document.head.appendChild(style);
    // init scripts
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/popper.min.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/bootstrap.min.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/owl.carousel.min.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/jquery.magnific-popup.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/contact.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/wow.min.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/waypoints.min.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/jquery.counterup.min.js');
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = path.resolve(process.env.PUBLIC_URL, 'js/main.js');
    document.body.appendChild(script);
  }

  render() {
    return <div id="appside">
      {this.props.children}
    </div>;
  }
}