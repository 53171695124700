import React, { Component } from "react";
import ReactTable from "react-table";
import apiClient from '../../components/Core/apiClient';
import Swal from "sweetalert2";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import SVGIcon from "../../components/Core/Icons/SVGIcons";

class Business extends Component {
  constructor(props) {
    super(props);
    require('react-table/react-table.css');
    require('react-notifications/lib/notifications.css');
    require("../../containers/reactTable.scss");
    require('./business.scss');
    this.state = {
      data: [],
      pages: null,
      loading: true,
      companies: null,
      selectedCompany: 0,
      tableState: null,
      searchValue: '',
      lastRowId: 0,
      currentPage: 0,
      currentPageSize: 10
    };
    this.fetchBusinesses = this.fetchBusinesses.bind(this);
    this.deleteBusiness = this.deleteBusiness.bind(this);
    this.onSearch = this.onSearch.bind(this);
  };
  onRowClick = (action, row, column) => {
    if (column.Header !== "Actions")
      this.props.history.push('/admin/business/edit/' + row.id);
  };
  mapBusinesses(companies) {
    let res = [];
    if (!companies || !companies.length)
      return res;

    for (let i = 0; i < companies.length; i++) {
      res.push({ value: companies[i]["id"], label: companies[i]["name"] });
    }
    return res;
  }

  async fetchBusinesses(state, instance) {
    //console.log(instance);
    let endpoint = "api/Business/List";
    this.setState({
      loading: true
    });
    await apiClient.post(endpoint,
      {
        "searchValue": this.state.searchValue === null ? "" : this.state.searchValue,
        "lastRowId": 0,
        "sortColumn": state.sorted === undefined || state.sorted.length === 0 ? "CreatedDate" : state.sorted[0]['id'],
        "isDescending": state.sorted === undefined || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
        "perPage": state.pageSize === undefined ? 10 : state.pageSize,
        "skip": state.page * state.pageSize
      }
    )
      .then(async response => {
        this.state.currentPageSize = state.pageSize;
        await this.setState({
          data: response.data["entities"],
          pages: Math.ceil(parseInt(response.data["total"]) / state.pageSize),
          loading: false,
          total: response.data["total"],
          tableState: state,
          lastRowId: response.data["lastRowId"]
        });
      }).catch(error => {
        console.error(`Axios request failed: ${error}`);
      });

  }

  deleteBusiness(id) {
    Swal.fire({
      // title: 'Are you sure?',
      text: "Are you sure that you want to delete this business?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#666FE8',
      cancelButtonColor: '#c9d1d8',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let endpoint = 'api/Business/Delete/' + id;
        apiClient.post(endpoint).then(response => {
          this.fetchBusinesses(this.state.tableState);
          NotificationManager.success('Business has been successfully deleted!', 'Success');
        }).catch(error => {
          NotificationManager.error(`${error}`, 'Error');
          console.error(`Axios request failed: ${error}`);
          Swal.close();
        });;
      }
    })
  }

  async onSearch(e) {
    await this.setState({ searchValue: e.target.value });
    this.fetchBusinesses(this.state.tableState);
  }

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div>
        <div className="business-list pb-2">
          <div className="business-list-header">
            <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Companies ({this.state.total})</h2>
            {/* <a href="/admin/business/create" className="add-btn btn bttn-success btn-elevate  btn-elevate-air">Add Business</a> */}
          </div>
          <div className="business-list-tools">
            <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
              <input type="text" className="form-control" placeholder="Search..." id="generalSearch" value={this.state.searchValue}
                onChange={this.onSearch} />
              <span className="kt-input-icon__icon kt-input-icon__icon--right">
                <span>
                  {SVGIcon.search}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="kt-portlet" style={{ borderRadius: "20px" }}>
          <div className="kt-portlet__body business-table-container">
            <ReactTable
              columns={[
                {
                  Header: "Name",
                  id: "name",
                  accessor: d => d.name
                },
                {
                  Header: "Address",
                  id: "address",
                  accessor: d => d.address
                },
                {
                  Header: "Suite",
                  id: "suite",
                  accessor: d => d.suite
                },
                {
                  Header: "City",
                  id: "city",
                  accessor: d => d.city
                },
                {
                  Header: "State",
                  id: "state",
                  accessor: d => d.state
                },
                {
                  Header: "Zip code",
                  id: "zipCode",
                  accessor: d => d.zipCode
                },
                {
                  Header: "Phone number",
                  id: "phoneNumber",
                  accessor: d => d.phoneNumber
                },
                {
                  Header: "Is Default",
                  id: "isDefault",
                  style: { textAlign: "center" },
                  accessor: d => d.isDefault,
                  Cell: props => [
                    <span className="kt-switch kt-switch--sm">
                      <label>
                        <input type="checkbox" checked={props.value === true ? "checked" : ""} name="" readOnly={true} />
                        <span></span>
                      </label>
                    </span>
                  ]
                },
                {
                  Header: 'Actions',
                  style: { textAlign: "center" },
                  id: "id",
                  sortable: false,
                  accessor: d => d.id,
                  Cell: props => [
                    <Link to={`/admin/business/edit/` + props.value} className="btn" key="edit-business" data-toggle="tooltip" title="Edit business">{SVGIcon.edit}</Link>,
                    <button onClick={() => this.deleteBusiness(props.value)} className="btn" key="delete-business" data-toggle="tooltip" title="Delete business">{SVGIcon.trash}</button>
                  ]
                }
              ]}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              data={data}
              pages={pages} // Display the total number of pages
              loading={loading} // Display the loading overlay when we need it
              onFetchData={this.fetchBusinesses} // Request new data when things change
              defaultPageSize={10}
              className="-striped -highlight"
              getTdProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (event, handleOriginal) => {
                      event.stopPropagation();
                      this.onRowClick("click", rowInfo.row._original, column);
                    }
                  };
                }
                return {};
              }}
            />
          </div>
        </div>
        <NotificationContainer />
      </div>

    );
  }
}

export default Business;