import React, { Component } from "react";
import apiClient from '../../components/Core/apiClient';
import { Tree, Button, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Question from './Question';
import Answer from './Answer';

export default class MessageTree extends Component {
    constructor(props) {
        super(props);
        require('antd/dist/antd.css');
        this.state = {
            treeData: [],
            treeDataLoaded: false,
        };
        this.fetchDatatypes = this.fetchDatatypes.bind(this);
        this.fetchTextNodes = this.fetchTextNodes.bind(this);
        this.traverseNode = this.traverseNode.bind(this);
        this.addRootQuestion = this.addRootQuestion.bind(this);
    };

    async componentDidMount() {
        await this.fetchDatatypes();
        await this.fetchTextNodes();
    }

    async fetchTextNodes() {
        await this.setState({
            treeData: []
        })
        await apiClient.get(`/api/Business/GetText/${this.props.codeId}`).then(async response => {
            const data = this.traverseNode(response.data, '0-0');
            if (data)
                await this.setState({
                    treeData: [data],
                    treeDataLoaded: true
                });
            else
                await this.setState({
                    treeData: [],
                    treeDataLoaded: true
                });
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    traverseNode(node, nodeKey) {
        if (node.type === 0) { //Question
            if (node.children.length === 0) {
                return {
                    title: <Question node={node} refreshTextNodes={this.fetchTextNodes} packageId={this.props.codeId} />,
                    key: nodeKey,
                }
            } else {
                const children = [];
                node.children.forEach((element, index) => {
                    children.push(
                        this.traverseNode(element, nodeKey + '-' + index)
                    )
                });
                return {
                    title: <Question node={node} refreshTextNodes={this.fetchTextNodes} packageId={this.props.codeId} />,
                    key: nodeKey,
                    children: children
                }
            }
        }
        if (node.type === 1) { //Answer
            if (node.children.length === 0) {
                return {
                    title: <Answer node={node} datatypes={this.state.datatypes} refreshTextNodes={this.fetchTextNodes} packageId={this.props.codeId} hasChild={false} />,
                    key: nodeKey,
                }
            } else {
                const children = [];
                node.children.forEach((element, index) => {
                    children.push(this.traverseNode(element, nodeKey + '-' + index))
                });
                return {
                    title: <Answer node={node} datatypes={this.state.datatypes} refreshTextNodes={this.fetchTextNodes} packageId={this.props.codeId} hasChild={true} />,
                    key: nodeKey,
                    children: children
                }
            }
        }
    }

    fetchDatatypes() {
        apiClient.get('/api/Keyword/ListDataType').then(response => {
            this.setState({
                datatypes: response.data,
            });
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    addRootQuestion() {
        const contextThis = this;
        apiClient.post(`/api/Business/AddText/${this.props.codeId}`, {
            sms: "",
            mms: "",
            type: 0,
            action: 0,
            saveAnswer: false,
            dataTypeId: null,
            parentId: null,
            children: []
        }).then(response => {
            notification.success({
                message: 'text is added successfully!'
            });
            contextThis.fetchTextNodes();
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    render() {
        if (this.state.treeDataLoaded) {
            if (this.props.approved) {

                return (
                    <>
                        <Tree
                            showLine
                            switcherIcon={<DownOutlined />}
                            treeData={this.state.treeData}
                            selectable={false}
                            defaultExpandAll={true}
                            autoExpandParent={true}
                        />
                        {this.state.treeData.length === 0 ?
                            <Button
                                onClick={this.addRootQuestion}
                            >
                                Add Question
                           </Button> : ""
                        }
                    </>
                );
            } else {
                return (
                    <></>
                );
            }
        } else {
            return (
                <></>
            );
        }
    }
}

