import React, { Component } from "react";
import ReactTable from "react-table";
import apiClient from '../../components/Core/apiClient';
import Swal from "sweetalert2";
import DropDown from "../../components/Form/DropDown";
import { AsYouType } from 'libphonenumber-js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import SVGIcon from "../../components/Core/Icons/SVGIcons";

class User extends Component {
  constructor(props) {
    super(props);
    require("react-table/react-table.css");
    require("react-notifications/lib/notifications.css");
    require("../../containers/reactTable.scss");
    require("./user.scss");
    this.state = {
      data: [],
      pages: null,
      loading: true,
      companies: null,
      selectedCompany: props.isAdmin === undefined ? 0 : null,
      tableState: null,
      searchValue: ''
    };
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchCompanies = this.fetchCompanies.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  };

  async componentDidMount() {
    await this.fetchCompanies();
  }

  async fetchUsers(state) {
    let endpoint = "api/Account/PaginatedList";
    await apiClient.post(endpoint,
      {
        "businessId": this.state.selectedCompany,
        "searchValue": this.state.searchValue === null ? "" : this.state.searchValue,
        "lastRowId": 0,
        "sortColumn": state.sorted === undefined || state.sorted.length === 0 ? "CreatedDate" : state.sorted[0]['id'],
        "isDescending": state.sorted === undefined || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
        "perPage": state.pageSize === undefined ? 10 : state.pageSize,
        "skip": state.page * state.pageSize
      }
    )
      .then(async response => {
        this.setState({
          data: response.data["entities"],
          pages: Math.ceil(parseInt(response.data["total"]) / state.pageSize),
          loading: false,
          total: response.data["total"],
          tableState: state,
          lastRowId: response.data["entities"].length === 0 ? 0 : response.data["entities"][response.data["entities"].length - 1].id
        });
      }).catch(error => {
        console.error(error);
        this.setState({
          loading: false
        });
        console.error(`Axios request failed: ${error}`);
      });

  }

  async fetchCompanies() {
    let endpoint = "api/Business/List";
    await apiClient.post(endpoint,
      {
        "searchValue": "",
        "lastRowId": 0,
        "sortColumn": "CreatedDate",
        "isDescending": true,
        "perPage": 1000
      }
    )
      .then(async response => {
        this.setState({
          companies: this.mapCompanies(response.data["entities"])
        });
      }).catch(error => {
        console.error(`Axios request failed: ${error}`);
      });
  }

  onRowClick = (action, row, column) => {
    if (column.Header !== "Actions") {
      this.props.isAdmin === undefined ? this.props.history.push('/admin/users/edit/' + row.id) : this.props[0].history.push('/users/edit/' + row.id);
    }
  }
  
  mergeRoles(roles) {
    let res = '';
    if (!roles || !roles.length)
      return res;

    for (let i = 0; i < roles.length; i++) {
      if (i === 0)
        res += roles[i]["name"];
      else {
        res += ' , ' + roles[i]["name"];
      }
    }
    return res;
  }

  mapCompanies(companies) {
    let res = [];
    if (!companies || !companies.length)
      return res;

    for (let i = 0; i < companies.length; i++) {
      res.push({ value: companies[i]["id"], label: companies[i]["name"] });
    }
    return res;
  }

  async deleteUser(id) {
    Swal.fire({
      // title: 'Are you sure?',
      text: "Are you sure that you want to delete this user?",
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#c9d1d8',
      confirmButtonColor: '#666FE8',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let endpoint = 'api/Account/Delete/' + id;
        apiClient.post(endpoint).then(response => {
          this.fetchUsers(this.state.tableState);
          NotificationManager.success('User has been successfully deleted!', 'Success');
        }).catch(error => {
          NotificationManager.error(`${error}`, 'Error');
          console.error(`Axios request failed: ${error}`);
          Swal.close();
        });;
      }
    })
  }

  async handleCompanyChange(selected) {
    await this.setState({
      selectedCompany: selected === null ? 0 : selected.value,
      loading: true
    },
    )
    this.fetchUsers(this.state.tableState);
  }

  async onSearch(e) {
    await this.setState({ searchValue: e.target.value });
    this.fetchUsers(this.state.tableState);
  }

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div>
        <div className="users-list mb-2">
          <div className="users-list-header">
            <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Users ({this.state.total})</h2>
            <a href="users/create" className="add-btn btn bttn-success">Add User</a>
          </div>
          <div className="users-list-tools">
            {this.props.isAdmin === undefined ?
              <div className="users-list-tools-company">
                <DropDown
                  onChange={this.handleCompanyChange}
                  options={this.state.companies}
                  isClearable={true}
                  placeholder="Choose a company"
                  selectedOption={this.state.selectedCompany}
                  isMulti={false} />
              </div>
              : ""
            }
            <div className="users-list-tools-search">
              <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                <input type="text" className="form-control" placeholder="Search..." id="generalSearch" value={this.state.searchValue}
                  onChange={this.onSearch} />
                <span className="kt-input-icon__icon kt-input-icon__icon--right">
                  <span>
                    {SVGIcon.search}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet" style={{ borderRadius: "20px" }}>
          <div className="kt-portlet__body user-table-container">
            <ReactTable
              columns={[
                {
                  Header: "First Name",
                  id: "firstName",
                  accessor: d => d.firstName
                },
                {
                  Header: "Last Name",
                  id: "lastName",
                  accessor: d => d.lastName
                },
                {
                  Header: "Email",
                  id: "email",
                  accessor: d => d.email
                },
                {
                  Header: "Phone Number",
                  id: "phoneNumber",
                  accessor: d => d.phoneNumber,
                  Cell: props => new AsYouType('US').input(props.value)
                },
                {
                  Header: "Role",
                  id: "roles",
                  accessor: d => this.mergeRoles(d.roles)
                },
                {
                  Header: "Company",
                  id: "business",
                  accessor: d => d.business
                },
                {
                  Header: 'Actions',
                  style: { textAlign: "center" },
                  id: "id",
                  sortable: false,
                  accessor: d => d.id,
                  Cell: props => [
                    this.props.isAdmin === undefined ?
                      <Link to={`/admin/users/edit/` + props.value} className="btn" key="edit-user">{SVGIcon.edit}</Link>
                      :
                      <Link to={`/users/edit/` + props.value} className="btn" key="edit-user" data-toggle="tooltip" title="Edit user">{SVGIcon.edit}</Link>,
                    <button onClick={() => this.deleteUser(props.value)} className="btn" key="delete-user" data-toggle="tooltip" title="Delete user">{SVGIcon.trash}</button>
                  ]
                }
              ]}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              data={data}
              pages={pages} // Display the total number of pages
              loading={loading} // Display the loading overlay when we need it
              onFetchData={this.fetchUsers} // Request new data when things change
              defaultPageSize={10}
              className="-striped -highlight"
              getTdProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (event, handleOriginal) => {
                      event.stopPropagation();
                      this.onRowClick("click", rowInfo.row._original, column);
                    }
                  };
                }
                return {};
              }}
            />
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

export default User;