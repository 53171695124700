import React from "react";
import apiClient from '../../components/Core/apiClient';
import ReactQueryParams from 'react-query-params';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Swal from "sweetalert2";
import Footer from '../../components/Footer/Footer';

class ResetPassword extends ReactQueryParams {
    constructor(props) {
        super(props);
        require("react-table/react-table.css");

        this.state = {
            email: "",
            email_error: false,
            password: "",
            confirm_password: "",
            password_error: false,
            code: this.queryParams.auth,
            reset_has_error: false,
            errors: ""
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.state.code === undefined) {
            this.props.history.push('/login')
        }
    }

    /* Functions */
    validateEmail(email) {
        const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if (result === true) {
            this.setState({
                email_error: false,
                email: email
            })
        } else {
            this.setState({
                email_error: true,
                email: ""
            })
        }
    };

    onInputChange = e => {
        if (e.target.name === 'email') {
            this.validateEmail(e.target.value);
        }

        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    password_error: true,
                    password: ""
                })
            } else {
                this.setState({
                    password_error: false,
                    password: e.target.value
                })
            }
        }

        if (e.target.name === 'confirm_password') {
            this.setState({
                confirm_password: e.target.value
            })
        }
    };

    handleSubmit = event => {
        event.preventDefault();
        Swal.fire({
            title: 'Processing',
            html: 'We are processing...',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });
        apiClient.post("api/Account/ResetPassword", {
            "email": this.state.email,
            "password": this.state.password,
            "confirmPassword": this.state.confirm_password,
            "code": this.state.code
        })
            .then(response => {
                Swal.close();
                Swal.fire({
                    type: 'success',
                    title: 'Success',
                    text: 'Your password has been successfully resetted!',
                }).then((result) => {
                    this.props.history.push('/login')
                });
            }).catch(error => {
                let errors = "";
                if (error.response.data.errors[""] !== undefined) {
                    errors += error.response.data.errors[""][0];
                }
                if (error.response.data.errors["Email"] !== undefined) {
                    errors += error.response.data.errors["Email"][0];
                }
                if (error.response.data.errors["Password"] !== undefined) {
                    errors += error.response.data.errors["Password"][0];
                }
                if (error.response.data.errors["ConfirmPassword"] !== undefined) {
                    errors += error.response.data.errors["ConfirmPassword"][0];
                }

                this.setState({
                    reset_has_error: true,
                    errors: errors
                });
                NotificationManager.error(`${error}`, 'Error');
                console.error(`Axios request failed: ${error}`);
                Swal.close();
            });
    }

    render() {
        let clssNme = "kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 ";
        clssNme += "kt-login--signin";

        let emailCssClass = "form-control";
        emailCssClass += this.state.email_error ? " is-invalid" : "";

        let passwordCssClass = "form-control";
        passwordCssClass += this.state.password_error ? " is-invalid" : "";
        return (
            <div>
                <div className="bg-image">
                    <div className="kt-header--fixed kt-header-mobile--fixed kt-aside--enabled kt-aside--fixed kt-page--loading">
                        <div className="kt-grid kt-grid--ver kt-grid--root">
                            <div className={clssNme} id="kt_login">
                                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                    <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                                        <div className="kt-login__container wh-container">
                                            {this.state.reset_has_error === true ?
                                                <div className="alert alert-danger" role="alert">
                                                    <div className="alert-text">{this.state.errors}</div>
                                                </div> : ""}
                                            <div className="rectangle_title"></div>
                                            <div className="kt-login__signin">
                                                <div className="kt-login__head">
                                                    <h1 className="kt-login__title roboto-slab tl-black">Reset Password</h1>
                                                    <div className="sign-grey-bar"></div>
                                                </div>
                                                <form onSubmit={this.handleSubmit} className="kt-form" action="">
                                                    <div className="input-group font-comfortaa">
                                                        <label className="tl-black font-weight-bold">Email/Username</label>
                                                        {this.state.email_error ? <div id="email_error" className="error invalid-feedback1">Valid email is required.</div> : ''}
                                                        <input required className={emailCssClass} type="email" placeholder=" " name="email" id="email" onChange={this.onInputChange} />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="tl-black small font-weight-bold">Password</label>
                                                        {this.state.password_error ? <div id="password_error" className="error invalid-feedback1">This field is required.</div> : ''}
                                                        <input required className={passwordCssClass} type="password" placeholder=" " name="password" id="password" onChange={this.onInputChange} />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="tl-black small font-weight-bold">Confirm Password</label>
                                                        {this.state.password_error ? <div id="password_error" className="error invalid-feedback1">This field is required.</div> : ''}
                                                        <input required className={passwordCssClass} type="password" placeholder=" " name="confirm_password" id="password" onChange={this.onInputChange} />
                                                    </div>
                                                    <div className="kt-login__actions">
                                                        <button type="submit" id="kt_login_signin_submit" className="btn bttn-success btn-width btn-shadow">Reset password</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer-area-account">
						<Footer />
					</footer>
                </div>
                <NotificationContainer />
            </div>
        );
    }
}

export default ResetPassword;