import React, { Component } from 'react';
import { Input } from "../../components/Form";
import DropDown from "../../components/Form/DropDown";
import DropDownNoAdd from "../../components/Form/DropDownNoAdd";
import apiClient from '../Core/apiClient';
import LocationSearchInput from '../../components/Map/LocationSearchInput';
import { Checkbox } from "@blueprintjs/core";

export class SearchForm extends Component {
    constructor(props) {
        super(props);
        require("./searchform.scss");
        this.state = {
            shortcodes: [],
            keywords: [],
            selectedShortcode: this.props.selectedShortCode,
            selectedKeywords: this.props.selectedKeyword,
            address: this.props.address,
            distance: this.props.distance,
            latitude: null,
            longitude: null,
            isLoading: true,
            prevDistance: 0,
            isNationwide: this.props.isNationwide,
            keyWordVisible: false,
            errorShortCode: false,
            errorKeyword: false,
            errorAddress: false,
            errorDistance: false,
            fromSearchResult: this.props.fromSearchResult,
        };
        this.handleShortCodeChange = this.handleShortCodeChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleNationWideChange = this.handleNationWideChange.bind(this);
    }

    componentDidMount() {
        this.shortCodeList();
        if (this.props.fromSearchResult) {
            this.keywordList()
        }
        this.setState({
            keyWordVisible: !this.props.isNationwide,
            isLoading: false
        });
        this.props.saveSearchFormHandler(this.handleFormSubmit);
    }
    shortCodeList() {
        apiClient.get('api/Business/ShortCodeList').then(response => {
            this.setState({
                shortcodes: response.data.map((o) => {
                    return { value: o.name, label: o.name };
                })
            })
        });
    }
    async keywordList() {
        if (this.state.selectedShortcode) {
            let endpoint = `api/Business/KeywordLists/?shortcode=${this.state.selectedShortcode.value}&isNationWide=${this.state.isNationwide}`;
            await apiClient.get(endpoint).then(async response => {
                await this.setState({
                    keywords: response.data.map((o) => {
                        return { value: o.name, label: o.name };
                    })
                });
            });
        }
    }
    async handleShortCodeChange(selected) {
        await this.setState({
            keywords: [],
            selectedShortcode: selected,
            errorShortCode: false,
            keyWordVisible: false,
            selectedKeywords: null
        });
        await this.setState({
            keyWordVisible: true,
        });
        this.keywordList();
    }
    async handleKeywordChange(selected) {
        if (selected != null && selected.__isNew__) {
            if (this.state.selectedShortcode) {
                let endpoint = `api/Business/CheckKeyword/?shortcode=${this.state.selectedShortcode.value}&keyword=${selected.value}&isNationWide=${this.state.isNationwide}`;
                await apiClient.get(endpoint).then(async response => {
                    if (response.data) { //Can add keyword
                        await this.setState({
                            selectedKeywords: selected,
                            errorKeyword: false
                        })
                    }
                    else { //Cannot add keyword
                        await this.setState({
                            keyWordVisible: false,
                        });
                        await this.setState({
                            keyWordVisible: true,
                        });
                        await this.setState({
                            selectedKeywords: null,
                            errorKeyword: false
                        });
                    }
                });
            }
        }
        else {
            await this.setState({
                selectedKeywords: selected,
                errorKeyword: false
            })
        }
    }
    handleAddress(addr, lat, lng) {
        this.setState({
            address: addr,
            latitude: lat,
            longitude: lng,
            errorAddress: false
        });
    }
    handleInputChange(event) {
        if (event.target.value > 100) {
            const value = this.state.prevDistance;
            var input = document.getElementById("distance");
            input.value = value;
        } else {
            const { name, value } = event.target;
            this.setState({
                [name]: value,
                errorDistance: false,
                prevDistance: value
            });
        }
    }
    async handleFormSubmit() {
        await this.validateForm();
        if (!this.state.isNationwide) {
            if (this.state.errorShortCode || this.state.errorKeyword || this.state.errorAddress || this.state.errorDistance)
                return;
            else
                this.props.handleInputChange({
                    shortCode: this.state.selectedShortcode,
                    keywords: this.state.selectedKeywords,
                    address: this.state.address,
                    latitude: this.state.latitude,
                    longitude: this.state.longitude,
                    distance: this.state.distance,
                    isNationwide: false
                });
        } else {
            if (this.state.errorShortCode || this.state.errorKeyword)
                return;
            else
                this.props.handleInputChange({
                    shortCode: this.state.selectedShortcode,
                    keywords: this.state.selectedKeywords,
                    isNationwide: true
                });
        }
    }
    async validateForm() {
        const { selectedShortcode, selectedKeywords, address, distance } = this.state;
        if (selectedShortcode == null) {
            await this.setState({ errorShortCode: true })
        }
        if (selectedKeywords == null) {
            await this.setState({ errorKeyword: true })
        }
        if (address === "") {
            await this.setState({ errorAddress: true })
        }
        if (distance == null || distance === "" || distance === "0") {
            await this.setState({ errorDistance: true })
        }
    }
    async handleNationWideChange() {
        const isNationwide = !this.state.isNationwide;
        this.setState({
            isNationwide: isNationwide
        });
        this.props.parentCallbackNationWide({
            isNationwide: isNationwide,
            shortCode: this.state.selectedShortcode,
            keywords: this.state.selectedKeywords,
        });
        await this.setState({
            keywords: [],
            keyWordVisible: false,
            selectedKeywords: null
        });
        await this.setState({
            keyWordVisible: true,
        });
        await this.keywordList();
    }
    render() {
        const { isLoading } = this.state;
        if (isLoading) {
            return "Loading";
        }
        return (
            <>
                <div className="searchform-wrapper">
                    <form onSubmit={this.handleFormSubmit} >
                        <div className="row">
                            <div className="col-xl-6 col-sm-12 landing-space mt-lg-0 mt-3">
                                <div className={"Shortcode-label"}>
                                    <label>Shortcode {this.state.errorShortCode ? <i className="error-content">This field is required!</i> : ''}</label>
                                    <DropDownNoAdd
                                        required={true}
                                        name='shortcodes'
                                        onChange={this.handleShortCodeChange}
                                        options={this.state.shortcodes}
                                        isClearable={false}
                                        isMulti={false}
                                        selectedOption={this.props.selectedShortCode}
                                    />
                                </div>
                            </div>
                            {this.state.keyWordVisible ?
                                <div className="col-xl-6 col-sm-12 mt-xl-0 mt-3">
                                    <div className={"Keyword-label"}>
                                        <label>Keyword {this.state.errorKeyword ? <i className="error-content">This field is required!</i> : ''}</label>
                                        <DropDown
                                            required={true}
                                            name='keywords'
                                            onChange={this.handleKeywordChange}
                                            options={this.state.keywords}
                                            isClearable={false}
                                            isMulti={false}
                                            selectedOption={this.state.selectedKeywords} />
                                    </div>
                                </div>
                                : <div className="col-xl-6 col-sm-12 mt-xl-0 mt-3">
                                    <div className={"Keyword-label"}>
                                        <label>Keyword {this.state.errorKeyword ? <i className="error-content">This field is required!</i> : ''}</label>
                                        <DropDownNoAdd
                                            required={true}
                                            name='keywords'
                                            isClearable={false}
                                            isMulti={false}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        {this.state.fromSearchResult ? " " :
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <Checkbox checked={this.state.isNationwide} label="Nationwide" style={{ userSelect: 'none' }} onChange={this.handleNationWideChange} />
                                </div>
                            </div>
                        }
                        {this.state.isNationwide ?
                            <>
                            </>
                            : <>
                                <div className="row no-gutters mt-2 formItem">
                                    <div className="col-md-6 address">
                                        <label>Address {this.state.errorAddress ? <i className="error-content">This field is required!</i> : ''}</label>
                                        <LocationSearchInput
                                            required
                                            handleAddress={this.handleAddress}
                                            address={this.props.address}
                                            name='address'
                                        />
                                    </div>
                                    <div className="col-md-6 distance">
                                        <label> Distance from address {this.state.errorDistance ? <i className="error-content"> Required!</i> : ''}</label>
                                        <div style={{ borderBottom: '1px solid white', width: '140px' }}>
                                            <Input
                                                onChange={this.handleInputChange}
                                                type="number"
                                                min={0}
                                                max={100}
                                                step={1}
                                                name="distance"
                                                placeholder="0"
                                                defaultValue={this.props.distance}
                                                id="distance"
                                                style={{
                                                    marginTop: '0'
                                                }}
                                            />
                                            <span style={{ color: 'white' }}>
                                                miles
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </form>
                </div>
            </>
        )
    }
}
export default SearchForm;
