import React, { Component } from "react";
import ReactTable from "react-table";
import apiClient from '../../components/Core/apiClient';
import Select from 'react-select';
import Workbook from 'react-excel-workbook';

class PaymentReport extends Component {
    /* Properties */

    /* Constructor */
    constructor(props) {
        super(props);
        require("react-table/react-table.css");
        require("react-notifications/lib/notifications.css");
        require("../../containers/reactTable.scss");

        this.state = {
            data: [],
            pages: null,
            loading: true,
            selectedBusiness: 0,
            tableState: null,
            total: 0,
            businessID: [],
        };

        this.fetch = this.fetch.bind(this);
        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    };

    /* Functions */
    fetch(state) {
        apiClient.post("api/Report/PaymentReport",
            {
                "businessIDs": this.state.businessID,
                "lastRowId": 0,
                "sortColumn": state === null || state === undefined || state.sorted === undefined || state.sorted.length === 0 ? "CreatedDate" : state.sorted[0]['id'],
                "isDescending": state === null || state === undefined || state.sorted === undefined || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
                "perPage": state === null || state === undefined || state.pageSize === undefined ? 10 : state.pageSize,
            }).then(response => {
                this.setState({
                    data: response.data["entities"],
                    total: response.data["entities"].length,
                    loading: false,
                    tableState: state,
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
                this.setState({
                    loading: false
                });
            });
    };

    fetchCompanies() {
        let endpoint = "api/Business/List";
        apiClient.post(endpoint,
            {
                "searchValue": "",
                "lastRowId": 0,
                "sortColumn": "CreatedDate",
                "isDescending": true,
                "perPage": 1000
            }
        )
            .then(response => {
                this.setState({
                    companies: this.mapCompanies(response.data["entities"])
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
            });
    };

    mapCompanies(companies) {
        let res = [];
        if (!companies || !companies.length)
            return res;

        for (let i = 0; i < companies.length; i++) {
            res.push({ value: companies[i]["id"], label: companies[i]["name"] });
        }
        return res;
    };

    /* Event */
    componentDidMount() {
        this.fetch();
        this.fetchCompanies();
    };

    async handleDropDownChange(event) {
        let businessIDs = null;
        if (event != null && event.length > 0) {
            businessIDs = [];
            event.forEach(element => {
                businessIDs.push(element.value);
            });
        }
        await this.setState({
            businessID: businessIDs
        });

        this.fetch(this.state.tableState);
    };

    async handleChange(event) {
        await this.setState({
            [event.target.id]: event.target.value
        });

        this.fetch(this.state.tableState);
    };

    render() {
        const { data, pages, loading } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Payments</h2>
                            </div>
                            <div className="col" style={{ maxWidth: "300px" }}>
                                <React.Fragment>
                                    <Select
                                        name='companies'
                                        onChange={this.handleDropDownChange}
                                        options={this.state.companies}
                                        isClearable={true}
                                        placeholder="Choose a company"
                                        selectedOption={this.state.businessID}
                                        isMulti={true} />
                                </React.Fragment>
                            </div>
                        </div>
                        <div className="kt-portlet kt-report">
                            <div className="kt-portlet__body user-table-container">
                                <ReactTable
                                    columns={[
                                        {
                                            Header: "Company",
                                            id: "BusinessName",
                                            accessor: d => d.businessName
                                        },
                                        {
                                            Header: "Amount",
                                            id: "amount",
                                            accessor: d => d.amount
                                        },
                                        {
                                            Header: "Description",
                                            id: "Description",
                                            accessor: d => d.description
                                        },
                                        {
                                            Header: "Status",
                                            id: "Status",
                                            accessor: d => d.status
                                        },
                                        {
                                            Header: "Created Date",
                                            id: "createdDate",
                                            accessor: d => d.createdDate
                                        }
                                    ]}
                                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                    data={data}
                                    pages={pages} // Display the total number of pages
                                    loading={loading} // Display the loading overlay when we need it
                                    onFetchData={this.fetch} // Request new data when things change
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                />
                                <Workbook filename="PaymentReport.xlsx" element={<button className="btn bttn-success btn-width btn-shadow search-button" style={{ marginTop: '20px' }} >Export to excel</button>}>
                                    <Workbook.Sheet data={data} name="Report">
                                        <Workbook.Column label="Company" value="businessName" />
                                        <Workbook.Column label="Amount" value="amount" />
                                        <Workbook.Column label="Description" value="description" />
                                        <Workbook.Column label="Status" value="status" />
                                        <Workbook.Column label="Created Date" value="createdDate" />
                                    </Workbook.Sheet>
                                </Workbook>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default PaymentReport;