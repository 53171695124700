import React from 'react';

export function Input(props) {
    return (
        <input id="input" className="form-control form-input" {...props} />
    );
  }

export function Button(props) {
  var className = "btn float-right " + props.className;
  return (
    <button {...props} className={className}  type="submit" onClick={props.onSubmit} >
      {props.children}
    </button>
  );
}