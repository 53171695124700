/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import apiClient from '../../components/Core/apiClient';
import Swal from "sweetalert2";
import Footer from '../../components/Footer/AccountFooter';

class Login extends Component {

	/* Constructor */
	constructor(props) {
		super(props);
		this.state = {
			isFPVisible: false,

			email: "",
			email_error: false,
			password: "",
			password_error: false,

			invalid_login_attempt: false,
			invalid_login_error: "",
			forgot_email: "",
			forgot_msg: ""
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
	};

	/* Functions */
	validateEmail(email) {
		const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-zA-Z0-9]{3,}[.][a-z]{2,5}/g;
		const result = pattern.test(email);
		if (result === true) {
			this.setState({
				email_error: false,
				email: email
			})
		} else {
			this.setState({
				email_error: true,
				email: ""
			})
		}
	};

	validateForm() {
		this.validateEmail(this.state.email);
		if (this.state.password === '' || this.state.password === null) {
			this.setState({
				password_error: true,
				password: ""
			})
		} else {
			this.setState({
				password_error: false
			})
		}
	};

	/* Events */
	handleClick() {
		this.setState(state => ({
			isFPVisible: !state.isFPVisible
		}));
	};

	handleChange(event) {
		this.validateEmail(event.target.value);
		this.setState({ [event.target.name]: event.target.value })
	}

	onInputChange = e => {
		if (e.target.name === 'email')
			this.validateEmail(e.target.value);

		if (e.target.name === 'password') {
			if (e.target.value === '' || e.target.value === null) {
				this.setState({
					password_error: true,
					password: ""
				})
			} else {
				this.setState({
					password_error: false,
					password: e.target.value
				})
			}
		}
	};

	handleSubmit = event => {
		event.preventDefault();
		this.validateForm()
		if (this.state.email_error || this.state.password_error || this.state.email.length === 0 || this.state.password.length === 0)
			return;

		apiClient.signIn(this.state.email, this.state.password)
			.then(response => {
				if (localStorage.getItem("roles").includes("Admin"))
					this.props.history.push('/items');
				else
					this.props.history.push('/messaging');
			}).catch(error => {
				this.setState({
					invalid_login_attempt: true,
					invalid_login_error: error.response.data.errors[""][0]
				})
			});
	};

	handleRequest = event => {
		event.preventDefault();
		this.validateForm()
		if (this.state.email.length === 0 || this.state.email === " ")
			alert("STOP!!!!! Enter your EMAIL address 😵 ");
		// this.setState({
		// 	email: null
		// })
	}

	handleForgotSubmit = event => {
		event.preventDefault();
		Swal.fire({
			title: 'Processing',
			html: 'We are processing...',
			onBeforeOpen: () => {
				Swal.showLoading()
			}
		});
		apiClient.post("api/Account/ForgotPassword?email=" + this.state.forgot_email)
			.then(response => {
				Swal.close();
				this.setState({
					forgot_msg: "Please check your email to reset your password."
				});
			}).catch(error => {

			});
	}

	render() {
		let clssNme = "kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 ";
		clssNme += this.state.isFPVisible ? "kt-login--forgot" : "kt-login--signin";

		let emailCssClass = "form-control";
		emailCssClass += this.state.email_error ? " is-invalid" : "";

		let passwordCssClass = "form-control";
		passwordCssClass += this.state.password_error ? " is-invalid" : "";
		return (
			<div>
				<div className="bg-image">
					<div className="kt-header--fixed kt-header-mobile--fixed kt-aside--enabled kt-aside--fixed kt-page--loading">
						<div className="kt-grid kt-grid--ver kt-grid--root">
							<div className={clssNme} id="kt_login">
								<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
									<div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
										<div className="kt-login__container wh-container">

											<div className="rectangle_title"></div>
											<div className="kt-login__signin">
												<div className="kt-login__head">
													<h1 className="kt-login__title roboto-slab tl-black">BT ShortCode Portal</h1>
												</div>
												<form className="kt-form" action="">
													<div className="input-group font-comfortaa">
														<label className="tl-black font-weight-bold">Email/Username</label>
														{this.state.email_error ? <div id="email_error" className="error invalid-feedback1">Valid email is required.</div> : ''}
														<input className={emailCssClass} type="email" placeholder=" " name="email" id="email" onChange={this.onInputChange} />
													</div>
													{/* <div className="row kt-login__extra">
														<div className="col kt-align-right">
															<label className="kt-checkbox small tl-black">
																<input type="checkbox" name="remember" /> Remember me<span></span>
															</label>
														</div>
													</div> */}
													<div className="input-group">
														<label className="tl-black small font-weight-bold">Password</label>
														{this.state.password_error ? <div id="password_error" className="error invalid-feedback1">This field is required.</div> : ''}
														<input className={passwordCssClass} type="password" placeholder=" " name="password" id="password" onChange={this.onInputChange} />
													</div>
													<div className="row kt-login__extra">
														<div className="col kt-align-right">
															<a href={null} id="kt_login_forgot" className="kt-login__link small tl-black" onClick={this.handleClick}>Forget Password?</a>
														</div>
													</div>
													<div>
														<input id="invalid-username-password" className="form-control is-invalid" type="hidden" />
														{this.state.invalid_login_attempt ? <div id="invalid_login_attempt" className="invalid-feedback">{this.state.invalid_login_error}</div> : ''}
													</div>
													<div className="kt-login__actions">
														<button id="kt_login_signin_submit" className="btn bttn-success btn-width btn-shadow" onClick={this.handleSubmit}>Sign In</button>
													</div>
												</form>
												<p className="tl-black font-weight-bold mt-3">Don't have an account? &nbsp;
													<a href="/" className="tl-black">Contact us.</a>
												</p>
											</div>
											<div className="kt-login__forgot forgot-section">
												{this.state.forgot_msg === "" ?
													<div className="kt-login__head">
														<h1 className="kt-login__title roboto-slab tl-black">Forgot Password</h1>
														<div className="kt-login__desc text-center font-weight-bold tl-black mt-4">Enter your email address and we will send you a code to reset your password.</div>
													</div>
													:
													<div className="kt-login__head">
														<div className="kt-login__desc text-center font-weight-bold tl-black mt-4">{this.state.forgot_msg}</div>
													</div>
												}
												{this.state.forgot_msg === "" ?
													<form onSubmit={this.handleForgotSubmit} className="kt-form" method="post">
														<div className="input-group">
															<label className="text-center font-weight-bold tl-black ">Email/Username</label>
															{this.state.email_error ? <div id="email_error" className="error invalid-feedback1">Valid email is required.</div> : ''}
															<input required className={emailCssClass} type="email" placeholder=" " name="forgot_email" id="forget_email" onChange={this.handleChange} />
														</div>
														<div className="kt-login__actions mt-5">
															<button type="submit" id="kt_login_forgot_submit" className="btn btn-shadow  bttn-success large">
																Send Password
														</button>
															<p className="tl-black font-weight-bold mt-4">Already have an account? &nbsp;
															<a href="/login" className="tl-black">Sign In.</a>
															</p>
														</div>
													</form>
													:
													""
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<footer className="footer-area-account">
						<Footer />
					</footer>
				</div>
			</div>
		);
	};
}
export default Login;