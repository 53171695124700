import React, { Component } from 'react';
import PhoneImg from '../../assets/images/pixel_CarAccident.jpg';
import apiClient from '../../components/Core/apiClient';
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import FormInput from "../../components/Form/FormInput";

class ContactInfo extends Component {
    constructor() {
        super();
        require('./contact-info.scss');
        this.state = {
            zipCodes: [],
            shortCode: "",
            keywords: "",
            address: "",
            latitude: null,
            longitude: null,
            distance: "",

            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",

            // TODO : Error messages
            errorFirstName: false,
            errorLastName: false,
            errorEmail: false,
            errorPhoneNumber: false,

            isNationwide: true
        };
        this.handleBack = this.handleBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount = () => {
        // set states for param values
        const data = this.props.location.state.data;
        if (data.isNationwide) {
            this.setState({
                isNationwide: data.isNationwide,
                shortCode: data.shortCode,
                keywords: data.keywords,
            });
        } else {
            this.setState({
                isNationwide: data.isNationwide,
                shortCode: data.shortCode,
                keywords: data.keywords,
                address: data.address,
                latitude: data.latitude,
                longitude: data.longitude,
                distance: data.distance,
                zipCodes: data.zipCodes,
            });
        }
    };

    handleBack() {
        if (this.state.isNationwide) {
            this.props.history.push({
                pathname: '/'
            });
        } else
            this.props.history.push({
                pathname: '/search-result',
                state: {
                    data: {
                        shortCode: this.state.shortCode,
                        keywords: this.state.keywords,
                        address: this.state.address,
                        latitude: this.state.latitude,
                        longitude: this.state.longitude,
                        distance: this.state.distance,
                    }
                }
            });
    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        if (name === 'firstName')
            this.setState({ errorFirstName: value === "" });
        if (name === 'lastName')
            this.setState({ errorLastName: value === "" });
        if (name === 'email') {
            const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,}[.][a-z]{2,5}/g;
            const result = pattern.test(value);
            if (result === true) {
                this.setState({
                    errorEmail: false,
                })
            } else {
                this.setState({
                    errorEmail: true,
                })
            }
        }
        if (name === 'phoneNumber') {
            const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            const result = pattern.test(value);
            if (result === true) {
                this.setState({
                    errorPhoneNumber: false,
                })
            } else {
                this.setState({
                    errorPhoneNumber: true,
                })
            }
        }
    };

    async validateForm() {
        if (this.state.firstName.length === 0)
            this.setState({ errorFirstName: true })
        if (this.state.lastName.length === 0)
            this.setState({ errorLastName: true })
        if (this.state.email.length === 0)
            this.setState({ errorEmail: true })
        if (this.state.phoneNumber.length === 0)
            this.setState({ errorPhoneNumber: true })
    };

    async handleSubmit(event) {
        // event.preventDefault();
        await this.validateForm()
        if (this.state.errorFirstName || this.state.errorLastName || this.state.errorEmail || this.state.errorPhoneNumber)
            return;
        var data = null;
        if (this.state.isNationwide) {
            data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                shortCode: this.state.shortCode.value,
                keyword: this.state.keywords.value,
                isNationwide: this.state.isNationwide
            };
        } else {
            data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                shortCode: this.state.shortCode.value,
                address: this.state.address,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                radius: this.state.distance,
                keyword: this.state.keywords.value,
                itemZipCodes: this.state.zipCodes.map(o => {
                    return { zipCode: o.key }
                }),
                isNationwide: this.state.isNationwide
            };
        }
        apiClient.post("api/Business/AddItem", data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    type: 'success',
                    title: 'Success',
                    text: 'Your request has been completed.',
                }).then(() => {
                    this.props.history.push('/');
                });
            } else
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong!',
                });
        });
    }

    render() {
        if (this.state.isNationwide) {
            return (
                <div>
                    <div className="bg-contact3">
                        <div className="container-contact3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="imgContainer" style={{
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img src={PhoneImg} alt="Phone" className="phoneImage" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row formContent" style={{ height: '190px' }}>
                                    <div className="col-md-6">
                                        <div className="reviewSection">
                                            <div className="row">
                                                <div className="col-12 col-md-7 col-lg-8 mb-3">
                                                    <h3 className="roboto-slab"> Review Your Selection </h3>
                                                </div>
                                                <div className="d-none d-md-block col-6 col-md-5 col-lg-4">
                                                    <button className="btn-width  bttn-success btn-shadow btn-custom-padding" id="redoBtn" onClick={this.handleBack}>
                                                        Back
                                                </button>
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <label> Shortcode: <span> <strong>{this.state.shortCode.value}</strong> </span> </label>
                                            </div>
                                            <div className="row no-gutters">
                                                <label> Keyword: <span> <strong>{this.state.keywords.value}</strong> </span> </label>
                                            </div>
                                            <div className="row no-gutters">
                                                <label> Location: <span> <strong>Nationwide</strong> </span> </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="wrap-contact3" style={{ marginTop: '-270px' }}>
                                            <form className="contact3-form validate-form">
                                                <div className="contact3-form-title">
                                                    <h4 className="text-center">Enter Your Contact Information</h4>
                                                    <span> Enter your email and phone number and a team member will be in touch with you shortly.</span>
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Name is required">
                                                    <FormInput value={this.state.firstName} onChange={this.handleInputChange} type="text" name="firstName" required={true} label="First Name" className="form-control" />
                                                    {this.state.errorFirstName ? <div className="error">Required</div> : ''}
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Name is required">
                                                    <FormInput value={this.state.lastName} onChange={this.handleInputChange} type="text" name="lastName" required={true} label="Last Name" className="form-control" />
                                                    {this.state.errorLastName ? <div className="error">Required</div> : ''}
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Phone number is required">
                                                    <label>Phone Number *</label>
                                                    <InputMask value={this.state.phoneNumber} onChange={this.handleInputChange} name="phoneNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                                                    {this.state.errorPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                                    <FormInput value={this.state.email} onChange={this.handleInputChange} type="email" name="email" required={true} label="Email" className="form-control" />
                                                    {this.state.errorEmail ? <div className="error">Valid email is required</div> : ''}
                                                </div>

                                                <div className="container-contact3-form-btn text-center contact3-form-center">
                                                    <button type="button" onClick={this.handleSubmit} className="btn bttn-success btn-shadow">Submit Keyword Request</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (!this.state.zipCodes.length)
                return null;
            return (
                <div>
                    <div className="bg-contact3">
                        <div className="container-contact3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="imgContainer">
                                            <img src={PhoneImg} alt="Phone" className="phoneImage" />
                                        </div>
                                        <button className="offset-8 col-4 d-sm-block d-md-none d-lg-none d-xl-none btn-width  bttn-success btn-shadow btn-custom-padding redo-keyword" id="redoBtn" onClick={this.handleBack}>
                                            Redo Keyword Search
                                </button>
                                    </div>
                                </div>
                                <div className="row formContent">
                                    <div className="col-md-6">
                                        <div className="reviewSection">
                                            <div className="row">
                                                <div className="col-12 col-md-7 col-lg-8">
                                                    <h3 className="roboto-slab"> Review Your Selection </h3>
                                                </div>
                                                <div className="d-none d-md-block col-6 col-md-5 col-lg-4">
                                                    <button className="btn-width  bttn-success btn-shadow btn-custom-padding" id="redoBtn" onClick={this.handleBack}>
                                                        Redo Keyword Search
                                            </button>
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <label> Shortcode: <span> <strong>{this.state.shortCode.value}</strong> </span> </label>
                                            </div>
                                            <div className="row no-gutters">
                                                <label> Keyword: <span> <strong>{this.state.keywords.value}</strong> </span> </label>
                                            </div>
                                            <div className="row no-gutters">
                                                <label> Address: <span> <strong>{this.state.address}</strong> </span> </label>
                                            </div>                                            
                                            <div className="row no-gutters">
                                                <label> Distance: <span> <strong>{this.state.distance}</strong> </span> </label>
                                            </div>                                            
                                            <div className="row no-gutters">
                                                <div className="col-md-12" style={{ margin: "5px 0 0" }}>
                                                    <h5> ZIP Codes: </h5>
                                                </div>
                                            </div>
                                            <div className="row no-gutters show-zips">
                                                <div className="col-md-12">
                                                    {
                                                        this.state.zipCodes.map(zipCode => (
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary  btn-zipcode"
                                                                key={zipCode.key} label="">
                                                                {zipCode.properties.zipCode}
                                                            </button>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="wrap-contact3">
                                            <form className="contact3-form validate-form">
                                                <div className="contact3-form-title">
                                                    <h4 className="text-center">Enter Your Contact Information</h4>
                                                    <span> Enter your email and phone number and a team member will be in touch with you shortly.</span>
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Name is required">
                                                    <FormInput value={this.state.firstName} onChange={this.handleInputChange} type="text" name="firstName" required={true} label="First Name" className="form-control" />
                                                    {this.state.errorFirstName ? <div className="error">Required</div> : ''}
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Name is required">
                                                    <FormInput value={this.state.lastName} onChange={this.handleInputChange} type="text" name="lastName" required={true} label="Last Name" className="form-control" />
                                                    {this.state.errorLastName ? <div className="error">Required</div> : ''}
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Phone number is required">
                                                    <label>Phone Number *</label>
                                                    <InputMask value={this.state.phoneNumber} onChange={this.handleInputChange} name="phoneNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                                                    {this.state.errorPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                                                </div>

                                                <div className="wrap-input3 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                                    <FormInput value={this.state.email} onChange={this.handleInputChange} type="email" name="email" required={true} label="Email" className="form-control" />
                                                    {this.state.errorEmail ? <div className="error">Valid email is required</div> : ''}
                                                </div>

                                                <div className="container-contact3-form-btn text-center contact3-form-center">
                                                    <button type="button" onClick={this.handleSubmit} className="btn bttn-success btn-shadow">Submit Keyword Request</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default ContactInfo;