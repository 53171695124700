import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import FormInput from '../../components/Form/FormInput';
import axios from 'axios';
import apiClient from "../../components/Core/apiClient";
import Swal from "sweetalert2";

import Shape1 from './images/08.png';
import Shape2 from './images/09.png';

class LocationLink extends Component {
    constructor(props) {
        super(props);
        require("./style.scss");
        this.state = {
            id: "",
            value: "",
            zipCode: "",
            isSubmitted: false,
        }
        this.handleGetLocation = this.handleGetLocation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleInputChange = event => {
        this.setState({
            zipCode: event.target.value
        })
    }
    handleGetLocation = (event) => {
        //console.log("getting location here");
        var self = this;
        if (document.location.protocol === 'https:' && (navigator.geolocation != null)) {
            navigator.geolocation.getCurrentPosition(function (pos) {
                var coords, url;
                coords = pos.coords;
                url = "https://nominatim.openstreetmap.org/reverse?format=json&lat=" + coords.latitude + "&lon=" + coords.longitude + "&addressdetails=1";
                axios({
                    url: url,
                    method: 'get'
                })
                    .then(response => {
                        self.setState({ zipCode: response.data.address.postcode });
                    })
                    .catch(err => {
                        console.error(err);
                    });
            });
        }
    }
    handleSubmit() {
        apiClient.post("api/Conversations/" + this.props.match.params.id + "/Location",
            {
                id: this.props.match.params.id,
                zipCode: this.state.zipCode
            }
        ).then(response => {
            Swal.fire({
                type: 'success',
                title: 'Success',
                text: 'Your request has been completed.',
            }).then(() => {
                // window.location.href = "https://btshortcode.net/";
                this.setState({
                    isSubmitted: true
                })
            });
        });
    };
    render() {
        const { zipCode, isSubmitted } = this.state;
        return (
            <div>
                <Header />
                <section className="circle-work-area">
                    <div className="shape-1"><img src={Shape1} alt="shape-1" /></div>
                    <div className="shape-2"><img src={Shape2} alt="shape-2" /></div>
                    <div className="shape-3"><img src={Shape1} alt="shape-3" /></div>
                    <div className="shape-4"><img src={Shape2} alt="shape-4" /></div>
                    <header className="header-area header-bg-4 style-four comfortaa " id="home">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div>
                                        <div className="header-inner text-center">
                                            {
                                                isSubmitted ? <> <h3 className="mt-5 wow fadeInDown">Thank you! Please see our new text just sent to you for help with your legal need.</h3> </> : <>
                                                    <h1 className="title wow fadeInDown">Discover Your Location</h1>
                                                    <button onClick={this.handleGetLocation} className="btn locationBtn">
                                                        <span > <i className="fas fa-map-marker-alt"></i> Use my current location</span>
                                                    </button> <br />
                                                    <FormInput style={{
                                                        width: "50%",
                                                        padding: "9px",
                                                        borderRadius: "5px",
                                                        border: "none",
                                                        color: "#272727",
                                                        fontSize: "20px",
                                                        backgroundColor: "white"
                                                    }}
                                                        value={zipCode}
                                                        onChange={this.handleInputChange}
                                                        name="zipcode"
                                                        type="number"
                                                        placeholder="Enter zipcode here"
                                                        required />
                                                    <button onClick={this.handleSubmit} className="btn bttn-success btn-width btn-shadow">Submit</button>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </section>
            </div>
        )
    }
}
export default LocationLink;


