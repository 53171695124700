import React, { Component } from "react";
import ReactTable from "react-table";
import FormInput from "../../components/Form/FormInput";
import Swal from "sweetalert2";
import InputMask from 'react-input-mask';
import apiClient from '../../components/Core/apiClient';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Checkbox } from "@blueprintjs/core";
import CardReactFormContainer from 'card-react';
import SVGIcon from "../../components/Core/Icons/SVGIcons";
import NumberFormat from 'react-number-format';

class Profile extends Component {
    constructor(props) {
        super(props);
        require('react-table/react-table.css');
        require("../../containers/reactTable.scss");
        require("./fileupload.min.scss");
        require("react-notifications/lib/notifications.css");
        require("react-notifications/lib/notifications.css");
        require('./card.css');
        require("./profile.scss");
        this.state = {
            activeTab: 1,

            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            id: 0,
            business_id: '',
            business_name: '',
            business_phoneNumber: '',
            business_callNumber: '',
            business_address1: '',
            business_address2: '',
            business_city: '',
            business_state: '',
            business_zip: '',
            business_email: '',
            business_isDefault: '',
            business_defaultSMS: '',
            business_defaultMMS: '',

            phoneNumbers: [],
            business_phone_number: "",
            error_business_phone_number: false,
            error_phone_bill: false,
            removedPhoneNumbers: [],
            phoneNumbersLoading: false,
            phone_bill: null,

            errorFirstName: false,
            errorLastName: false,
            errorEmail: false,
            errorPhoneNumber: false,
            errorOldPassword: false,
            errorNewPassword: false,
            errorConfirmPassword: false,
            showPasswordSection: false,

            errorBusinessName: false,
            errorBusinessPhoneNumber: false,
            errorBusinessAddress1: false,
            errorBusinessAddress2: false,
            errorBusinessAddressCity: false,
            errorBusinessAddressState: false,
            errorBusinessAddressZipcode: false,
            errorBusinessDefaultSMS: false,

            payment_cardnumber: '',
            payment_cvc: '',
            payment_expiry: '',
            payment_name: '',

            payment_address: '',
            payment_city: '',
            payment_state: '',
            payment_zip: '',

            error_payment_address: '',
            error_payment_city: '',
            error_payment_state: '',
            error_payment_zip: '',
            error_payment_cardnumber: false,
            error_payment_cvc: false,
            error_payment_expiry: false,
            error_payment_name: false,

            invalid_payment_cardnumber: false,
            invalid_payment_cvc: false,
            invalid_payment_expiry: false,
            invalid_payment_name: false,

            admin: localStorage.getItem("roles").includes("Admin"),
            attorney: localStorage.getItem("roles").includes("Attorney"),
            staff: localStorage.getItem("roles").includes("Staff"),
        };
        this.handleChange = this.handleChange.bind(this);

        this.handleAccountSubmit = this.handleAccountSubmit.bind(this);
        this.validateProfile = this.validateProfile.bind(this);
        this.handleAccountSubmitDialog = this.handleAccountSubmitDialog.bind(this);

        this.handleBusinessSubmit = this.handleBusinessSubmit.bind(this);
        this.validateBusiness = this.validateBusiness.bind(this);
        this.handleBusinessSubmitDialog = this.handleBusinessSubmitDialog.bind(this);

        this.handlePaymentSubmit = this.handlePaymentSubmit.bind(this);
        this.validatePayment = this.validatePayment.bind(this);
        this.handlePaymentSubmitDialog = this.handlePaymentSubmitDialog.bind(this);

        this.getCurrentAccountID = this.getCurrentAccountID.bind(this);
        this.fetchProfile = this.fetchProfile.bind(this);
        this.fetchBusiness = this.fetchBusiness.bind(this);
        this.fetchPaymentInfo = this.fetchPaymentInfo.bind(this);
        this.fetchPhoneNumbers = this.fetchPhoneNumbers.bind(this);
        this.addPhoneNumber = this.addPhoneNumber.bind(this);
        this.removePhoneNumber = this.removePhoneNumber.bind(this);
    };

    fetchPhoneNumbers() {
        apiClient.post('/api/Business/PhoneNumberList')
            .then(response => {
                this.setState({
                    phoneNumbers: response.data,
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
            });
    }

    addPhoneNumber() {
        var file = this.state.phone_bill;
        var context = this;


        if (this.state.business_phone_number === "" || file === null) {
            if (file === null) {
                this.setState({
                    error_phone_bill: true
                });
            }

            if (this.state.business_phone_number === "") {
                this.setState({
                    error_business_phone_number: true
                });
            }

            return;
        }

        this.setState({
            phoneNumbersLoading: true
        });

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            var phoneNumber = context.state.business_phone_number.replace(/\D/g, "");
            var base64 = reader.result.split(',')[1];
            apiClient.post('/api/Business/PhoneNumbers/CreateOrUpdate',
                {
                    'phoneNumber': phoneNumber,
                    'businessId': context.state.business_id,
                    'file': {
                        'value': base64,
                        'name': file.name,
                        'extension': file.type,
                        'contentType': file.type,
                        'fileSize': file.size
                    }
                })
                .then(response => {
                    context.fetchPhoneNumbers();
                    context.setState({
                        phoneNumbersLoading: false,
                        business_phone_number: "",
                        phone_bill: null
                    });
                })
                .catch(error => {
                    console.error(`Axios request failed: ${error}`);
                    context.setState({
                        phoneNumbersLoading: false,
                        business_phone_number: "",
                        phone_bill: null
                    });
                });
        };
        reader.onerror = function (error) {
            console.error('Error: ', error);
        };
    };

    fileChangedHandler = (event) => {
        this.setState({
            phone_bill: event.target.files[0],
            error_phone_bill: false
        });
    }

    async removePhoneNumber(id) {
        Swal.fire({
            text: "Are you sure that you want to remove this phone number?",
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#c9d1d8',
            confirmButtonColor: '#666FE8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                var context = this;
                this.setState({
                    phoneNumbersLoading: true
                });
                apiClient.post('/api/Business/PhoneNumbers/Delete/' + id)
                    .then(response => {
                        NotificationManager.success('Phone has been successfully removed!', 'Success');
                        context.fetchPhoneNumbers();
                        this.setState({
                            phoneNumbersLoading: false
                        });
                    })
                    .catch(error => {
                        console.error(`Axios request failed: ${error}`);
                        this.setState({
                            phoneNumbersLoading: false
                        });
                    });
            }
        });
    };

    createOrUpdate() {
        apiClient.post('/api/Business/PhoneNumberList', this.state.phoneNumbers)
            .then(response => {
                this.setState({
                    phoneNumbers: response.data,
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
            });
    }

    async componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const tab = params.get('tab');
        this.setState({ activeTab: parseInt(tab) });
        await this.getCurrentAccountID();
        await this.fetchProfile();
        await this.fetchBusiness();
        await this.fetchPaymentInfo();
        await this.fetchPhoneNumbers();
    }

    async fetchBusiness() {
        let endpoint = "api/Business/" + this.state.business_id;
        await apiClient.get(endpoint
        ).then(async response => {
            await this.setState({
                business_name: response.data['name'] === null ? '' : response.data['name'],
                business_phoneNumber: response.data['phoneNumber'] === null ? '' : response.data['phoneNumber'],
                business_callNumber: response.data['callNumber'] === null ? '' : response.data['callNumber'],
                business_address1: response.data['address1'] === null ? '' : response.data['address1'],
                business_address2: response.data['address2'] === null ? '' : response.data['address2'],
                business_city: response.data['city'] === null ? '' : response.data['city'],
                business_state: response.data['state'] === null ? '' : response.data['state'],
                business_zip: response.data['zip'] === null ? '' : response.data['zip'],
                business_isDefault: response.data['isDefault'] === null ? '' : response.data['isDefault'],
                business_defaultSMS: response.data['defaultSMS'] === null ? '' : response.data['defaultSMS'],
                business_defaultMMS: response.data['defaultMMS'] === null ? '' : response.data['defaultMMS']
            });
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    async getCurrentAccountID() {
        let endpoint = "api/Account/GetCurrentAccountID";
        await apiClient.get(endpoint)
            .then(async response => {
                console.error(response.data);
                await this.setState({
                    id: response.data
                });
            });
    }

    async fetchProfile() {
        let endpoint = "api/Account/" + this.state.id;
        await apiClient.get(endpoint
        )
            .then(async response => {
                await this.setState({
                    email: response.data['email'] === null ? '' : response.data['email'],
                    phoneNumber: response.data['phoneNumber'] === null ? '' : response.data['phoneNumber'],
                    firstName: response.data['firstName'] === null ? '' : response.data['firstName'],
                    lastName: response.data['lastName'] === null ? '' : response.data['lastName'],
                    password: '',
                    confirmPassword: '',
                    business_id: response.data['businessId'] === null ? '' : response.data['businessId']
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
            });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
        if (name === 'firstName')
            this.setState({ errorFirstName: value === "" });

        if (name === 'business_phone_number')
            this.setState({ error_business_phone_number: false });

        if (name === 'lastName')
            this.setState({ errorLastName: value === "" });
        if (name === 'email') {
            const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,}[.][a-z]{2,5}/g;
            const result = pattern.test(value);
            if (result === true) {
                this.setState({
                    errorEmail: false,
                })
            } else {
                this.setState({
                    errorEmail: true,
                })
            }
        }
        if (name === 'phoneNumber') {
            const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            const result = pattern.test(value);
            this.setState({ errorPhoneNumber: !result });
        }
        if (name === 'changePassword') {
            this.setState({ showPasswordSection: !this.state.showPasswordSection })
        }
        if (name === 'oldPassword') {
            this.setState({ errorOldPassword: value === "" });
        }
        if (name === 'newPassword') {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!;@#$%^&*])(?=.{6,})/;
            const result = pattern.test(value);
            this.setState({ errorNewPassword: !result });
        }
        if (name === 'confirmPassword')
            this.setState({ errorConfirmPassword: value !== this.state.newPassword });

        if (name === 'business_name')
            this.setState({ errorBusinessName: value === "" });
        if (name === 'business_phoneNumber')
            this.setState({ errorBusinessPhoneNumber: value === "" });
        if (name === 'business_address1')
            this.setState({ errorBusinessAddress1: value === "" });
        if (name === 'business_address2')
            this.setState({ errorBusinessAddress2: value === "" });
        if (name === 'business_city')
            this.setState({ errorBusinessAddressCity: value === "" });
        if (name === 'business_state')
            this.setState({ errorBusinessAddressState: value === "" });
        if (name === 'business_zip')
            this.setState({ errorBusinessAddressZipcode: value === "" });
        if (name === 'business_defaultSMS')
            this.setState({ errorBusinessDefaultSMS: value === "" });

        if (name === 'payment_cardnumber') {
            this.setState({ error_payment_cardnumber: value === "" });
            this.setState({ invalid_payment_cardnumber: false })
        }
        if (name === 'payment_name') {
            this.setState({ error_payment_name: value === "" });
            this.setState({ invalid_payment_name: false })
        }
        if (name === 'payment_expiry') {
            this.setState({ error_payment_expiry: value === "" });
            this.setState({ invalid_payment_expiry: false })
        }
        if (name === 'payment_cvc') {
            this.setState({ error_payment_cvc: value === "" });
            this.setState({ invalid_payment_cvc: false })
        }
        if (name === 'payment_address')
            this.setState({ error_payment_address: value === "" });
        if (name === 'payment_city')
            this.setState({ error_payment_city: value === "" });
        if (name === 'payment_state')
            this.setState({ error_payment_state: value === "" });
        if (name === 'payment_zip')
            this.setState({ error_payment_zip: value === "" });


    }
    // Profile Submit
    async handleAccountSubmit(event) {
        await this.validateProfile();
        if (this.state.errorFirstName || this.state.errorLastName || this.state.errorEmail || this.state.errorPhoneNumber) {
            return false;
        } else if (this.state.showPasswordSection) {
            if (this.state.errorOldPassword || this.state.errorNewPassword || this.state.errorConfirmPassword) {
                return false;
            } else this.handleAccountSubmitDialog();
        } else {
            this.handleAccountSubmitDialog();
        }
    }

    async validateProfile() {
        if (this.state.firstName.length === 0)
            this.setState({ errorFirstName: true })
        if (this.state.lastName.length === 0)
            this.setState({ errorLastName: true })
        if (this.state.email.length === 0)
            this.setState({ errorEmail: true })
        if (this.state.phoneNumber.length === 0)
            this.setState({ errorPhoneNumber: true })
        if (this.state.showPasswordSection) {
            if (this.state.oldPassword.length === 0)
                await this.setState({ errorOldPassword: true })
            if (this.state.newPassword.length === 0)
                await this.setState({ errorNewPassword: true })
            if (this.state.confirmPassword.length === 0)
                await this.setState({ errorConfirmPassword: true })
        }
    }

    handleAccountSubmitDialog() {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure that you want to update profile?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#666FE8',
            cancelButtonColor: '#c9d1d8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Processing',
                    html: 'We are processing your request...',
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                });
                let endpoint = "api/Account/Update";
                apiClient.post(endpoint, {
                    email: this.state.email,
                    phoneNumber: this.state.phoneNumber,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    currentPassword: this.state.showPasswordSection ? this.state.oldPassword : "",
                    newPassword: this.state.newPassword,
                    businessId: 0,
                    id: this.state.id
                })
                    .then(response => {
                        NotificationManager.success('User has been successfully updated!', 'Success');
                        Swal.close();
                    }).catch(error => {
                        NotificationManager.error(error.response.data.errors[""][0], 'Error');
                        Swal.close();
                    });

            }
        })
    }

    // Business Submit
    async handleBusinessSubmit(event) {
        await this.validateBusiness();
        if (this.state.errorBusinessName || this.state.errorBusinessPhoneNumber || this.state.errorBusinessAddress1
            || this.state.errorBusinessAddress2 || this.state.errorBusinessAddressCity || this.state.errorBusinessAddressState
            || this.state.errorBusinessAddressZipcode || this.state.errorBusinessDefaultSMS) {
            return false;
        } else {
            this.handleBusinessSubmitDialog();
        }
    }

    async validateBusiness() {
        if (this.state.business_name.length === 0)
            this.setState({ errorBusinessName: true })
        if (this.state.business_phoneNumber.length === 0)
            this.setState({ errorBusinessPhoneNumber: true })
        if (this.state.business_address1.length === 0)
            this.setState({ errorBusinessAddress1: true })
        if (this.state.business_address2.length === 0)
            this.setState({ errorBusinessAddress2: true })
        if (this.state.business_city.length === 0)
            this.setState({ errorBusinessAddressCity: true })
        if (this.state.business_state.length === 0)
            this.setState({ errorBusinessAddressState: true })
        if (this.state.business_zip.length === 0)
            this.setState({ errorBusinessAddressZipcode: true })
        if (this.state.business_defaultSMS.length === 0)
            this.setState({ errorBusinessDefaultSMS: true })
    }

    handleBusinessSubmitDialog() {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure that you want to update information of this business?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#666FE8',
            cancelButtonColor: '#c9d1d8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Processing',
                    html: 'We are processing your request...',
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                });
                let endpoint = "api/Business/Update";
                apiClient.post(endpoint, {
                    name: this.state.business_name,
                    phoneNumber: this.state.business_phoneNumber,
                    callNumber: this.state.business_callNumber,
                    address1: this.state.business_address1,
                    address2: this.state.business_address2,
                    city: this.state.business_city,
                    state: this.state.business_state,
                    zip: this.state.business_zip,
                    isDefault: this.state.business_isDefault,
                    id: this.state.business_id,
                    defaultSMS: this.state.business_defaultSMS,
                    defaultMMS: this.state.business_defaultMMS
                }).then(response => {
                    NotificationManager.success('Business has been successfully updated!', 'Success');
                    Swal.close();
                }).catch(error => {
                    console.error(error);
                    NotificationManager.error(error.response.data.errors[""][0], 'Error');
                    console.error(`Axios request failed: ${error}`);
                    Swal.close();
                });
            }
        })
    }

    // Payment Submit
    async handlePaymentSubmit(event) {
        await this.validatePayment();
        if (this.state.error_payment_address || this.state.error_payment_city || this.state.error_payment_state || this.state.error_payment_zip ||
            this.state.invalid_payment_cardnumber || this.state.invalid_payment_city || this.state.invalid_payment_state || this.state.invalid_payment_zip
        )
            return;
        this.handlePaymentSubmitDialog();
    }

    async validatePayment() {
        if (this.state.payment_address.length === 0)
            this.setState({ error_payment_address: true })
        if (this.state.payment_city.length === 0)
            this.setState({ error_payment_city: true })
        if (this.state.payment_state.length === 0)
            this.setState({ error_payment_state: true })
        if (this.state.payment_zip.length === 0)
            this.setState({ error_payment_zip: true })

        if (this.state.payment_cardnumber.length === 0) {
            this.setState({ error_payment_cardnumber: true })
        } else if ((" " + document.getElementsByName("payment_cardnumber")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
            this.setState({ invalid_payment_cardnumber: true })
        }
        if (this.state.payment_name.length === 0) {
            this.setState({ error_payment_name: true })
        } else if ((" " + document.getElementsByName("payment_name")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
            this.setState({ invalid_payment_name: true })
        }
        if (this.state.payment_expiry.length === 0) {
            this.setState({ error_payment_expiry: true })
        } else if ((" " + document.getElementsByName("payment_expiry")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
            this.setState({ invalid_payment_expiry: true })
        }

        if (this.state.payment_cvc.length === 0) {
            this.setState({ error_payment_cvc: true })
        } else if ((" " + document.getElementsByName("payment_cvc")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
            this.setState({ invalid_payment_cvc: true })
        }
    }

    handlePaymentSubmitDialog() {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure that you want to update payment information?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Processing',
                    html: 'We are processing your request...',
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                });
                let endpoint = "api/Account/UpdatePaymentInfo";
                apiClient.post(endpoint, {
                    "cardNumber": this.state.payment_cardnumber,
                    "expirationDate": this.state.payment_expiry,
                    "cvCode": this.state.payment_cvc,
                    "firstName": this.state.payment_name,
                    "lastName": '',
                    "address": this.state.payment_address,
                    "city": this.state.payment_city,
                    "state": this.state.payment_state,
                    "zip": this.state.payment_zip,
                    "businessId": this.state.business_id
                })
                    .then(response => {
                        NotificationManager.success('Payment info has been successfully updated!', 'Success');
                        Swal.close();
                    }).catch(error => {
                        NotificationManager.error(error.response.data.errors[""][0], 'Error');
                        console.error(`Axios request failed: ${error}`);
                        Swal.close();
                    });
            }
        })
    }

    //Fetch data
    async fetchPaymentInfo() {
        let endpoint = "api/Account/GetPaymentInfo";
        await apiClient.get(endpoint
        )
            .then(async response => {
                await this.setState({
                    payment_card: 'XXXXXXXXXXXX' + response.data['cardNumber'],
                    payment_cardnumber: response.data['cardNumber'],
                    payment_expiry: response.data['expirationDate'] === null ? '' : response.data['expirationDate'],
                    payment_cvc: response.data['cvCode'] === null ? '' : response.data['cvCode'],
                    payment_name: response.data['firstName'] === null ? '' : response.data['firstName'] + " " + response.data['lastName'],
                    payment_address: response.data['address'] === null ? '' : response.data['address'],
                    payment_city: response.data['city'] === null ? '' : response.data['city'],
                    payment_state: response.data['state'] === null ? '' : response.data['state'],
                    payment_zip: response.data['zip'] === null ? '' : response.data['zip']
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
            });
    }

    render() {
        let classNameProfileLink = "nav-link " + (this.state.activeTab === 1 ? "active" : "");
        let classNameBusinessLink = "nav-link " + (this.state.activeTab === 2 ? "active" : "");
        let classNamePaymentLink = "nav-link " + (this.state.activeTab === 3 ? "active" : "");
        let classNamePhoneNumberLink = "nav-link " + (this.state.activeTab === 4 ? "active" : "");

        let classNameProfileTab = "tab-pane fade " + (this.state.activeTab === 1 ? "show active" : "");
        let classNameBusinessTab = "tab-pane fade " + (this.state.activeTab === 2 ? "show active" : "");
        let classNamePaymentTab = "tab-pane fade " + (this.state.activeTab === 3 ? "show active" : "");
        let classNamePhoneNumberTab = "tab-pane fade " + (this.state.activeTab === 4 ? "show active" : "");
        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <h3 className="page-title roboto-slab text-left font-weight-bold mb-3" style={{ marginLeft: "32px" }}>User Profile</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="profile-container">
                        <div className="col-md-12 row m-0 p-0">
                            <div className="nav flex-column nav-pills col-md-3 p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <div className="little-description">
                                    <h3 className="roboto-slab font-weight-bold">Your User Settings</h3>
                                    <p className="font-weight-normal mt-3">Update your user settings</p>
                                </div>
                                <a className={classNameProfileLink} id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">Profile Information</a>
                                {this.state.attorney && <a className={classNameBusinessLink} id="v-pills-business-tab" data-toggle="pill" href="#v-pills-business" role="tab" aria-controls="v-pills-business" aria-selected="false">Business Information</a>}
                                {this.state.attorney && <a className={classNamePaymentLink} id="v-pills-payment-tab" data-toggle="pill" href="#v-pills-payment" role="tab" aria-controls="v-pills-payment" aria-selected="false">Payment Information</a>}
                                {(this.state.attorney || this.state.staff) && <a className={classNamePhoneNumberLink} id="v-pills-phone-number-tab" data-toggle="pill" href="#v-pills-phone-number" role="tab" aria-controls="v-pills-phone-number" aria-selected="false">Phone Information</a>}
                            </div>
                            <div className="tab-content col-md-9" id="v-pills-tabContent">
                                <div className={classNameProfileTab} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="headline">
                                        <h3 className="font-weight-bold roboto-slab">Profile Information</h3>
                                        <p className="font-weight-normal mt-3">Update your profile information</p>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="kt-portlet__body">
                                                <div className="form-group form-group-last">
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-md-6 tl-black">
                                                        <FormInput value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" label="First Name" className="form-control" required={true} />
                                                        {this.state.errorFirstName ? <div className="error">Please enter your first name</div> : ''}
                                                    </div>
                                                    <div className="col-md-6 tl-black">
                                                        <FormInput value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" label="Last Name" className="form-control" required={true} />
                                                        {this.state.errorLastName ? <div className="error">Please enter your last name</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-md-6 tl-black">
                                                        <FormInput value={this.state.email} onChange={this.handleChange} type="email" name="email" label="Email" className="form-control" required={true} />
                                                        {this.state.errorEmail ? <div className="error">Please enter your email</div> : ''}
                                                    </div>
                                                    <div className="col-md-6 tl-black">
                                                        <div className="form-group custom-landing-form">
                                                            <label>Phone Number<span className="font-weight-normal">*</span></label>
                                                            <InputMask value={this.state.phoneNumber} type="text" onChange={this.handleChange} name="phoneNumber" className="form-control" mask="(999) 999-9999" maskChar=" " />
                                                        </div>
                                                        {this.state.errorPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-md-6 tl-black change-password-switcher">
                                                        <Checkbox checked={this.state.isEnabled} label="Change Password" name="changePassword" onChange={this.handleChange} large={true} />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.showPasswordSection ?
                                                        <div className="password-panel" >
                                                            <div className="form-group row">
                                                                <div className="col-md-6 tl-black">
                                                                    <FormInput value={this.state.oldPassword} onChange={this.handleChange} type="password" name="oldPassword" label="Current Password" className="form-control" required={true} />
                                                                    {this.state.errorOldPassword ? <div className="error">Please enter your current password</div> : ''}
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-md-6 tl-black">
                                                                    <FormInput value={this.state.newPassword} onChange={this.handleChange} type="password" name="newPassword" label="New Password" className="form-control" required={true} />
                                                                    {this.state.errorNewPassword ? <div className="error">At least 6 characters and contain at the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)</div> : ''}
                                                                </div>
                                                                <div className="col-md-6 tl-black">
                                                                    <FormInput value={this.state.confirmPassword} onChange={this.handleChange} type="password" name="confirmPassword" label="Confirm New Password" className="form-control" required={true} />
                                                                    {this.state.errorConfirmPassword ? <div className="error">Password not match</div> : ''}
                                                                </div>
                                                            </div>
                                                        </div> : ""
                                                }
                                            </div>
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions">
                                                    <button type="button" onClick={this.handleAccountSubmit} className="btn bttn-success btn-width btn-shadow float-right" style={{ marginRight: "25px" }}>Save</button>
                                                    {/* <a href="/messaging" className="btn bg-light-grey  btn-width">Cancel</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNameBusinessTab} id="v-pills-business" role="tabpanel" aria-labelledby="v-pills-business-tab">
                                    <div className="headline">
                                        <h3 className="font-weight-bold roboto-slab">Business information</h3>
                                        <p className="font-weight-normal mt-3">Update your business account details</p>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <FormInput value={this.state.business_name} onChange={this.handleChange} type="text" name="business_name" required={true} label="Business Name" className="form-control" />
                                                    {this.state.errorBusinessName ? <div className="error">Please enter business name</div> : ''}
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group custom-landing-form">
                                                        <label>Phone Number</label>
                                                        <span className="font-weight-normal">*</span>
                                                        <InputMask value={this.state.business_phoneNumber} onChange={this.handleChange} name="business_phoneNumber" required={true} className="form-control" mask="(999) 999-9999" maskChar=" " type="text" />
                                                    </div>
                                                    {this.state.errorBusinessPhoneNumber ? <div className="error">Please enter number</div> : ''}
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group custom-landing-form">
                                                        <label>Call Number</label>
                                                        <InputMask value={this.state.business_callNumber} onChange={this.handleChange} name="business_callNumber" required={true} className="form-control" mask="(999) 999-9999" maskChar=" " type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-7">
                                                    <FormInput value={this.state.business_address1} onChange={this.handleChange} type="text" name="business_address1" required={true} label="Business Address" className="form-control" />
                                                    {this.state.errorBusinessAddress1 ? <div className="error">Please enter address</div> : ''}
                                                </div>
                                                <div className="col-md-5">
                                                    <FormInput value={this.state.business_address2} onChange={this.handleChange} type="text" name="business_address2" required={true} label="Suite" className="form-control" />
                                                    {this.state.errorBusinessAddress2 ? <div className="error">Please enter suite</div> : ''}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-5">
                                                    <FormInput value={this.state.business_city} onChange={this.handleChange} type="text" name="business_city" required={true} label="City" className="form-control" />
                                                    {this.state.errorBusinessAddressCity ? <div className="error">Please enter city</div> : ''}
                                                </div>
                                                <div className="col-md-3">
                                                    <FormInput value={this.state.business_state} onChange={this.handleChange} type="text" name="business_state" required={true} label="State" className="form-control" />
                                                    {this.state.errorBusinessAddressState ? <div className="error">Please enter state</div> : ''}
                                                </div>
                                                <div className="col-md-4">
                                                    <FormInput value={this.state.business_zip} onChange={this.handleChange} type="text" name="business_zip" required={true} label="Zip Code" className="form-control" />
                                                    {this.state.errorBusinessAddressZipcode ? <div className="error">Please enter zip code</div> : ''}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <FormInput value={this.state.business_defaultSMS} onChange={this.handleChange} type="text" name="business_defaultSMS" required={true} label="Default SMS" className="form-control" />
                                                    {this.state.errorBusinessDefaultSMS ? <div className="error">Please enter default SMS text</div> : ''}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <FormInput value={this.state.business_defaultMMS} onChange={this.handleChange} type="text" name="business_defaultMMS" required={false} label="Default MMS" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions">
                                                    <button type="submit" onClick={this.handleBusinessSubmit} className="btn bttn-success btn-width btn-shadow float-right" style={{ marginRight: "25px" }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNamePaymentTab} id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                                    <div className="headline">
                                        <h3 className="font-weight-bold roboto-slab">Payment Information</h3>
                                        <p className="font-weight-normal mt-3">Update your payment settings</p>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="kt-portlet__body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div id="card-wrapper" className="mb-4"></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <CardReactFormContainer
                                                            container="card-wrapper"
                                                            formInputsNames={
                                                                {
                                                                    number: 'payment_cardnumber', // optional — default "number"
                                                                    expiry: 'payment_expiry',// optional — default "expiry"
                                                                    cvc: 'payment_cvc', // optional — default "cvc"
                                                                    name: 'payment_name' // optional - default "name"
                                                                }
                                                            }
                                                            classes={
                                                                {
                                                                    valid: 'valid-input', // optional — default 'jp-card-valid'
                                                                    invalid: 'invalid-input' // optional — default 'jp-card-invalid'
                                                                }
                                                            }
                                                            formatting={true}
                                                        >
                                                            <div className="row payment-form-group">
                                                                <div className="col-md-6 payment-form-input">
                                                                    <div className="form-group custom-landing-form">
                                                                        <label htmlFor="payment_cardnumber">Card Number</label><span>*</span><br></br>
                                                                        <input type="text" name="payment_cardnumber" className="form-control" onChange={this.handleChange} />
                                                                    </div>
                                                                    {this.state.error_payment_cardnumber ? <div className="error">Required</div> : ''}
                                                                    {this.state.invalid_payment_cardnumber ? <div className="error-invalid">Invalid</div> : ''}
                                                                </div>
                                                                <div className="col-md-6 payment-form-input">
                                                                    <div className="form-group custom-landing-form">
                                                                        <label htmlFor="payment_name">Name on Card</label><span>*</span><br></br>
                                                                        <input type="text" name="payment_name" className="form-control" onChange={this.handleChange} value={this.state.payment_name} />
                                                                    </div>
                                                                    {this.state.error_payment_name ? <div className="error">Required</div> : ''}
                                                                    {this.state.invalid_payment_name ? <div className="error-invalid">Invalid</div> : ''}
                                                                </div>
                                                                <div className="col-md-6 payment-form-input">
                                                                    <div className="form-group custom-landing-form">
                                                                        <label htmlFor="payment_expiry">Expiration Date</label><span>*</span><br></br>
                                                                        <input type="text" name="payment_expiry" className="form-control" onChange={this.handleChange} />
                                                                    </div>
                                                                    {this.state.error_payment_expiry ? <div className="error">Required</div> : ''}
                                                                    {this.state.invalid_payment_expiry ? <div className="error-invalid">Invalid</div> : ''}
                                                                </div>
                                                                <div className="col-md-6 payment-form-input">
                                                                    <div className="form-group custom-landing-form">
                                                                        <label htmlFor="payment_cvc">Security Code (CVC)</label><span>*</span><br></br>
                                                                        <input type="text" name="payment_cvc" className="form-control" onChange={this.handleChange} value={this.state.payment_cvc} />
                                                                    </div>
                                                                    {this.state.error_payment_cvc ? <div className="error">Required</div> : ''}
                                                                    {this.state.invalid_payment_cvc ? <div className="error-invalid">Invalid</div> : ''}
                                                                </div>
                                                            </div>
                                                        </CardReactFormContainer>
                                                    </div>
                                                </div>
                                                <div className="sub-headline">
                                                    <h4>Billing Address</h4>
                                                </div>
                                                <div className="row payment-form-group">
                                                    <div className="col-md-12 payment-form-input">
                                                        <FormInput value={this.state.payment_address} onChange={this.handleChange} type="text" name="payment_address" required={true} label="Address" className="form-control" />
                                                        {this.state.error_payment_address ? <div className="error">Required</div> : ''}
                                                    </div>
                                                    <div className="col-md-6 payment-form-input">
                                                        <FormInput value={this.state.payment_city} onChange={this.handleChange} type="text" name="payment_city" required={true} label="City" className="form-control" />
                                                        {this.state.error_payment_city ? <div className="error">Required</div> : ''}
                                                    </div>
                                                    <div className="col-md-3 payment-form-input">
                                                        <FormInput value={this.state.payment_state} onChange={this.handleChange} type="text" name="payment_state" required={true} label="State" className="form-control" />
                                                        {this.state.error_payment_state ? <div className="error">Required</div> : ''}
                                                    </div>
                                                    <div className="col-md-3 payment-form-input">
                                                        <FormInput value={this.state.payment_zip} onChange={this.handleChange} type="text" name="payment_zip" required={true} label="Zip Code" className="form-control" />
                                                        {this.state.error_payment_zip ? <div className="error">Required</div> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions">
                                                    <button type="submit" onClick={this.handlePaymentSubmit} className="btn bttn-success btn-width btn-shadow float-right" style={{ marginRight: "25px" }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNamePhoneNumberTab} id="v-pills-phone-number" role="tabpanel" aria-labelledby="v-pills-phone=number-tab">
                                    <div className="headline">
                                        <h3 className="font-weight-bold roboto-slab">Phone Information</h3>
                                        <p className="font-weight-normal mt-3">Update your phone number information</p>
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: "Phone number",
                                                            id: "phone-number",
                                                            accessor: d => d.phoneNumber,
                                                            Cell: props => [
                                                                <span><NumberFormat value={props.value} displayType={'text'} format="(###) ###-####" /></span>
                                                            ]
                                                        },
                                                        {
                                                            Header: "Status",
                                                            id: "phone-bill",
                                                            accessor: d => d.status,
                                                            Cell: props => [
                                                                <span className={props.value === 0 ? "pending" : "active"}>
                                                                    {props.value === 0 ? "Pending" : "Active"}
                                                                </span>
                                                            ]
                                                        },
                                                        {
                                                            Header: 'Actions',
                                                            style: { textAlign: "center" },
                                                            id: "id",
                                                            sortable: false,
                                                            accessor: d => d.phoneNumber,
                                                            Cell: props => [
                                                                <button onClick={() => this.removePhoneNumber(props.value)} className="btn" key="delete-phone-number" data-toggle="tooltip" title="Delete phone number">{SVGIcon.trash}</button>
                                                            ]
                                                        }
                                                    ]}
                                                    data={this.state.phoneNumbers}
                                                    loading={this.state.phoneNumbersLoading} // Display the loading overlay when we need it
                                                    onFetchData={this.fetchPhoneNumbers} // Request new data when things change
                                                    defaultPageSize={5}
                                                    showPagination={true}
                                                    className="-striped -highlight"
                                                />
                                                <div className="form-group row phone-information">
                                                    <div className="col-md-5 tl-black">
                                                        <div className="form-group custom-landing-form">
                                                            <label>Phone Number</label>
                                                            <InputMask
                                                                onChange={this.handleChange}
                                                                name="business_phone_number"
                                                                className="form-control"
                                                                mask="(999) 999-9999"
                                                                maskChar=" " type="text"
                                                                value={this.state.business_phone_number}
                                                            />
                                                        </div>
                                                        {this.state.error_business_phone_number ? <div className="error">Please enter business phone number</div> : ''}
                                                    </div>
                                                    <div className="col-md-5 tl-black">
                                                        <FormInput onChange={this.fileChangedHandler} type="file" name="phone_bill" label="Phone Bill" className="form-control file-input" required={true} />
                                                        {this.state.error_phone_bill ? <div className="error">Please select your file</div> : ''}
                                                    </div>
                                                    <div className="col-md-2 tl-black remove-phone-number">
                                                        <button onClick={this.addPhoneNumber} type="button" className="btn bttn-success btn-width btn-shadow">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </div >
        );
    }
}

export default Profile;