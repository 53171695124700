import React, { Component } from "react";
import axios from 'axios';
import apiClient from '../../components/Core/apiClient';
import { NotificationContainer } from 'react-notifications';
import DropDown from "../../components/Form/DropDown";
import DropDownNoAdd from "../../components/Form/DropDownNoAdd";
import { Checkbox } from "@blueprintjs/core";
import LocationSearchInput from '../../components/Map/LocationSearchInput';
import { Input } from "../../components/Form";
import MapComponent from '../../components/Map/map';
import Swal from "sweetalert2";

export default class RequestCreate extends Component {
    constructor(props) {
        super(props);
        require("react-table/react-table.css");
        require("react-notifications/lib/notifications.css");
        require("../../containers/reactTable.scss");
        require("./Item.scss");
        this.state = {
            companies: null,
            shortcodes: [],
            keywords: [],
            zipCodes: [],
            polygons: [],
            usedZipCodes: [],

            selectedCompany: 0,
            selectedShortcode: 0,
            selectedKeywords: 0,

            isNationwide: true,
            hasMaps: false,

            address: '',
            distance: 0,
            latitude: 0,
            longitude: 0,
            price: 0,

            mapAddress: '',
            mapDistance: 0,
            mapLatitude: 0,
            mapLongitude: 0,

            errorCompany: false,
            errorShortCode: false,
            errorKeyword: false,
            errorPrice: false,
            errorAddress: false,
            errorDistance: false,

            isAdmin: localStorage.getItem("roles").includes("Admin"),
        };
        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.fetchShortcodes = this.fetchShortcodes.bind(this);
        this.fetchKeywords = this.fetchKeywords.bind(this);

        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleShortCodeChange = this.handleShortCodeChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleNationWideChange = this.handleNationWideChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleDistanceChange = this.handleDistanceChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleLocationSearch = this.handleLocationSearch.bind(this);
        this.handleCreateRequest = this.handleCreateRequest.bind(this);

        this.searchZipCode = this.searchZipCode.bind(this);
        this.validate = this.validate.bind(this);
    };

    componentDidMount() {
        this.fetchCompanies();
        this.fetchShortcodes();
    }

    //#region Fetch data
    async fetchCompanies() {
        let endpoint = "api/Business/List";
        await apiClient.post(endpoint, {
            "searchValue": "",
            "lastRowId": 0,
            "sortColumn": "CreatedDate",
            "isDescending": true,
            "perPage": 1000
        }).then(async response => {
            await this.setState({
                companies: this.mapCompanies(response.data["entities"]),
                loading: false
            });
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    async fetchShortcodes() {
        apiClient.get('api/Business/ShortCodeList').then(response => {
            this.setState({
                shortcodes: response.data.map((o) => {
                    return { value: o.name, label: o.name };
                })
            })
        });
    }

    async fetchKeywords() {
        if (this.state.selectedShortcode) {
            let endpoint = `api/Business/KeywordLists/?shortcode=${this.state.selectedShortcode.value}&isNationWide=${this.state.isNationwide}`;
            await apiClient.get(endpoint).then(async response => {
                await this.setState({
                    keywords: response.data.map((o) => {
                        return { value: o.name, label: o.name };
                    })
                });
            });
        }
    }
    //#endregion

    //#region Handle Input

    async handleCompanyChange(selected) {
        await this.setState({
            selectedCompany: selected === null ? 0 : selected.value,
            errorCompany: false
        });
    }

    async handleShortCodeChange(selected) {
        await this.setState({
            keywords: [],
            selectedShortcode: selected,
            errorShortCode: false,
            keyWordVisible: false,
            selectedKeywords: null
        });
        await this.setState({
            keyWordVisible: true,
        });
        await this.fetchKeywords();
    }

    async handleKeywordChange(selected) {
        if (selected != null && selected.__isNew__) {
            if (this.state.selectedShortcode) {
                let endpoint = `api/Business/CheckKeyword/?shortcode=${this.state.selectedShortcode.value}&keyword=${selected.value}&isNationWide=${this.state.isNationwide}`;
                await apiClient.get(endpoint).then(async response => {
                    if (response.data) { //Can add keyword
                        await this.setState({
                            selectedKeywords: selected,
                            errorKeyword: false
                        })
                    }
                    else { //Cannot add keyword
                        await this.setState({
                            keyWordVisible: false,
                        });
                        await this.setState({
                            keyWordVisible: true,
                        });
                        await this.setState({
                            selectedKeywords: null,
                        });
                    }
                });
            }
        } else {
            await this.setState({
                selectedKeywords: selected,
                errorKeyword: false
            });
        }
    }

    async handleNationWideChange(e) {
        const isNation = e.target.checked;
        await this.setState({
            keywords: [],
            keyWordVisible: false,
            isNationwide: isNation,
            zipCodes: []
        });
        await this.setState({
            keyWordVisible: true,
        });
        await this.fetchKeywords();
        if (isNation) {
            await this.setState({
                address: '',
                distance: 0,
                hasMaps: false,
                errorAddress: false,
                errorDistance: false
            })
        }
    }

    async handleAddressChange(addr, lat, lng) {
        await this.setState({
            address: addr,
            latitude: lat,
            longitude: lng,
            errorAddress: addr === '',
        });
    }

    async handleDistanceChange(e) {
        const newRange = e.target.value;
        await this.setState({
            distance: newRange,
            errorDistance: newRange <= 0,
        });
    }

    async handlePriceChange(e) {
        const newPrice = e.target.value;
        await this.setState({
            price: newPrice,
            errorPrice: newPrice <= 0,
        });
    }

    async handleLocationSearch() {
        await this.setState({
            hasMaps: true,
            mapAddress: this.state.address,
            mapDistance: this.state.distance,
            mapLatitude: this.state.latitude,
            mapLongitude: this.state.longitude
        })
        await this.usedZipCodeList(this.state.selectedShortcode, this.state.selectedKeywords);
        await this.searchZipCode(this.state.mapDistance, this.state.mapLatitude, this.state.mapLongitude);
    }

    async handleCreateRequest() {
        const validation = await this.validate();
        if (!validation)
            return;
        var data = null;
        if (this.state.isNationwide) {
            data = {
                shortCode: this.state.selectedShortcode.value,
                keyword: this.state.selectedKeywords.value,
                isNationwide: this.state.isNationwide,
                businessId: this.state.selectedCompany,
                isAdmin: this.state.isAdmin,
                price: this.state.price
            };
        } else {
            data = {
                shortCode: this.state.selectedShortcode.value,
                keyword: this.state.selectedKeywords.value,
                isNationwide: this.state.isNationwide,
                address: this.state.address,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                radius: this.state.distance,
                itemZipCodes: this.state.zipCodes.map(o => {
                    return { zipCode: o.key }
                }),
                businessId: this.state.selectedCompany,
                isAdmin: this.state.isAdmin,
                price: this.state.price
            };
        }
        await apiClient.post("api/Business/AddItemFromAdmin", data).then(async response => {
            if (response.status === 200) {
                Swal.fire({
                    type: 'success',
                    title: 'Success',
                    text: 'Your request has been completed.',
                }).then(() => {
                    this.props.history.push('/items');
                });
            } else
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong!',
                });
        });
    }
    //#endregion

    //#region Helper 
    mapCompanies(companies) {
        let res = [];
        if (!companies || !companies.length)
            return res;

        for (let i = 0; i < companies.length; i++) {
            res.push({ value: companies[i]["id"], label: companies[i]["name"] });
        }
        return res;
    }

    formatPhoneNumber(phoneNumber) {
        if (phoneNumber)
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        else
            return "-";
    }

    async searchZipCode(radius, latitude, longitude) {
        await axios({
            url: 'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary/location?radius=' + radius
                + '&latitude=' + latitude + '&longitude=' + longitude,
            method: 'get',
            headers: {
                'X-RapidAPI-Host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
                'X-RapidAPI-Key': '50dd6f6f04msh065ffab2e0fd2d5p1a86adjsnb1bd857ca0ab'
            }
        }).then(async response => {
            let polygons = []
            let zipCodes = []
            await response.data.features.map(region => {
                let used = this.state.usedZipCodes.indexOf(region.properties.zipCode) === -1 ? false : true;
                if (!used) {
                    zipCodes.push({
                        key: region.properties.zipCode,
                        properties: region.properties
                    });
                }
                return region.geometry.coordinates.map((coord, i) => {
                    let coordArr = []
                    if (coord.length === 1)
                        coord[0].map(coodinate => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
                    else
                        coord.map(coodinate => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
                    polygons.push({
                        key: region.properties.zipCode + "_" + i,
                        coords: coordArr,
                        used: used
                    })
                    return coordArr
                })
            })
            let errorZipCodes = zipCodes.length === 0 ? true : false;
            this.setState({ zipCodes: zipCodes, errorZipCodes: errorZipCodes })
            this.setState({ polygons: polygons })
        }).catch(err => {
            console.error(err);
        });
    }

    async validate() {
        var validation = true;
        if (this.state.selectedShortcode === 0) {
            await this.setState({
                errorShortCode: true,
            });
            validation = false;
        }
        if (this.state.selectedKeywords === 0) {
            await this.setState({
                errorKeyword: true,
            });
            validation = false;
        }
        if (!this.state.isNationwide) {
            if (!this.state.address || this.state.address === "") {
                await this.setState({
                    errorAddress: true,
                });
                validation = false;
            }
            if (this.state.distance <= 0) {
                await this.setState({
                    errorDistance: true,
                });
                validation = false;
            }
        }
        if (this.state.isAdmin) {
            if (this.state.selectedCompany === 0) {
                await this.setState({
                    errorCompany: true,
                });
                validation = false;
            }
            if (this.state.price <= 0) {
                await this.setState({
                    errorPrice: true,
                });
                validation = false;
            }
        }
        return validation;
    }

    async usedZipCodeList(shortCode, keywords) {
        let endpoint = 'api/Business/UsedZipCodes?shortCode=' + shortCode.value + '&keywords=' + keywords.value;
        await apiClient.get(endpoint).then(async response => {
            if (response.status === 200) {
                await this.setState({ usedZipCodes: response.data })
            }
            else this.setState({ usedZipCodes: [] })
        });
    }
    //#endregion

    render() {
        if (this.state.loading) {
            return <></>
        } else {
            return (
                <>
                    <div className="packages-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 item-create-header">
                                    <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">
                                        {this.state.isAdmin ?
                                            "Create a package"
                                            : "Request new package"}
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 kt-item">
                                    {this.state.isAdmin ?
                                        <div className="col-md-12">
                                            <div className="detail_group">
                                                <label className="title">
                                                    <span className="roboto-slab desc-title">Company: </span>
                                                    <span className="desc_secondary company-selection selection">
                                                        <DropDownNoAdd
                                                            onChange={this.handleCompanyChange}
                                                            options={this.state.companies}
                                                            isClearable={false}
                                                            placeholder="Choose a company"
                                                            isMulti={false} />
                                                    </span>
                                                    <span className="error-msg">
                                                        {this.state.errorCompany ? <i className="error-content">This field is required!</i> : ''}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        : ''}
                                    <div className="col-md-12">
                                        <div className="detail_group">
                                            <label className="title">
                                                <span className="roboto-slab desc-title">Shortcode:</span>
                                                <span className="desc_secondary shortcode-selection selection">
                                                    <DropDownNoAdd
                                                        required={true}
                                                        name='shortcodes'
                                                        onChange={this.handleShortCodeChange}
                                                        options={this.state.shortcodes}
                                                        isClearable={false}
                                                        isMulti={false}
                                                        selectedOption={this.props.selectedShortCode}
                                                    />
                                                </span>
                                                <span className="error-msg">
                                                    {this.state.errorShortCode ? <i className="error-content">This field is required!</i> : ''}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="detail_group">
                                            <label className="title">
                                                <span className="roboto-slab desc-title">Keyword:</span>
                                                <span className="desc_secondary keyword-selection selection">
                                                    {this.state.keyWordVisible ?
                                                        <DropDown
                                                            required={true}
                                                            name='keywords'
                                                            onChange={this.handleKeywordChange}
                                                            options={this.state.keywords}
                                                            isClearable={false}
                                                            isMulti={false}
                                                        />
                                                        :
                                                        <DropDownNoAdd
                                                            required={true}
                                                            name='keywords'
                                                            isClearable={false}
                                                            isMulti={false}
                                                        />
                                                    }
                                                </span>
                                                <span className="error-msg">
                                                    {this.state.errorKeyword ? <i className="error-content">This field is required!</i> : ''}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.isAdmin ?
                                        <div className="col-md-12">
                                            <div className="detail_group">
                                                <label className="title">
                                                    <span className="roboto-slab desc-title">Price:</span>
                                                    <span className="desc_secondary price-input">
                                                        <span className="input-group-addon">$</span>
                                                        <input type="number" id="price" className="desc_price"
                                                            value={this.state.price}
                                                            onChange={this.handlePriceChange}
                                                            placeholder="0"
                                                        />
                                                    </span>
                                                    <span className="error-msg">
                                                        {this.state.errorPrice ? <i className="error-content">This field is required!</i> : ''}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        : ""}
                                    <div className="col-md-12">
                                        <div className="detail_group">
                                            <label className="title" style={{
                                                display: 'inline-flex',
                                                alignItems: 'center'
                                            }}>
                                                <span className="roboto-slab desc-title">Nationwide: </span>
                                                <span className="desc_secondary nationwide-checkbox">
                                                    <Checkbox checked={this.state.isNationwide} style={{ userSelect: 'none' }} onChange={this.handleNationWideChange} />
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.isNationwide ? "" :
                                        <>
                                            <div className="col-md-12">
                                                <div className="detail_group">
                                                    <label className="title">
                                                        <span className="roboto-slab desc-title">Address:</span>
                                                        <span className="desc_secondary address-input">
                                                            <LocationSearchInput
                                                                required
                                                                handleAddress={this.handleAddressChange}
                                                                address=''
                                                                name='address'
                                                            />
                                                        </span>
                                                        <span className="error-msg">
                                                            {this.state.errorAddress ? <i className="error-content">This field is required!</i> : ''}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="detail_group">
                                                    <label className="title">
                                                        <span className="roboto-slab desc-title">Distance:</span>
                                                        <span className="desc_secondary distance-input">
                                                            <Input
                                                                onChange={this.handleDistanceChange}
                                                                type="number"
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                name="distance"
                                                                placeholder="0"
                                                                defaultValue={0}
                                                                id="distance"
                                                                style={{
                                                                    marginTop: '0'
                                                                }}
                                                            />
                                                        </span>
                                                        <span className="roboto-slab" style={{ color: '#2e85d7', fontWeight: 'normal', marginLeft: '.5rem' }}>
                                                            mile(s)
                                                    </span>
                                                        {(this.state.distance > 0 && this.state.latitude > 0 && this.state.selectedShortcode && this.state.selectedKeywords) ?
                                                            <button
                                                                className="btn btn-width btn-shadow button  search-btn bttn-success"
                                                                onClick={this.handleLocationSearch}
                                                            > Search zipcode </button>
                                                            :
                                                            <button
                                                                className="btn btn-width btn-shadow button  search-btn bttn-success"
                                                                disabled
                                                            >Search zipcode</button>
                                                        }
                                                        <span className="error-msg">
                                                            {this.state.errorDistance ? <i className="error-content">This field is required!</i> : ''}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            {this.state.zipCodes.length > 0 ?
                                                <div className="col-md-12">
                                                    <div className="detail_group">
                                                        <label className="title">
                                                            <span className="roboto-slab desc-title">Zipcodes: </span>
                                                            <span className="desc_secondary zip-list" style={{ marginTop: '6px' }}>
                                                                {
                                                                    this.state.zipCodes.map(element => {
                                                                        return <span
                                                                            className="zipcode-item"
                                                                            key={element.key}
                                                                        >
                                                                            {element.properties.zipCode}
                                                                        </span>
                                                                    })
                                                                }
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                : ""}
                                        </>
                                    }
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    className="btn btn-width float-right btn-shadow button  btn-normal req-btn"
                                                    onClick={this.handleCreateRequest}>
                                                    Create
                                            </button>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                {this.state.hasMaps ?
                                    <div className="col-md-6 map-section">
                                        <MapComponent
                                            address={this.state.mapAddress}
                                            center={{ lat: this.state.mapLatitude, lng: this.state.mapLongitude }}
                                            polygons={this.state.polygons}
                                            radius={this.state.mapDistance}
                                            handleZipCode={this.handleZipCode}
                                        />
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                        <NotificationContainer />
                    </div>
                </>
            )
        }
    }
}
