import React, { Component } from "react";
import ReactTable from "react-table";
import apiClient from '../../components/Core/apiClient';
import Select from 'react-select';
import { AsYouType } from 'libphonenumber-js';
import { DateRangeInput } from "@blueprintjs/datetime";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import Workbook from 'react-excel-workbook';

class UserRequestReport extends Component {
    /* Properties */

    /* Constructor */
    constructor(props) {
        super(props);
        require("react-table/react-table.css");
        require("react-notifications/lib/notifications.css");
        require("../../containers/reactTable.scss");
        require("./UserRequestReport.scss");

        var tempDate = new Date();
        var monthAgo = tempDate.getFullYear() + '-' + (tempDate.getMonth()) + '-' + tempDate.getDate();
        var now = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + (tempDate.getDate() + 1);
        this.state = {
            data: [],
            pages: null,
            loading: true,
            selectedBusiness: this.props.isAdmin === undefined ? 0 : null,
            tableState: null,
            total: 0,
            shortcodes: null,
            keywords: null,

            businessID: this.props.isAdmin === undefined ? null : [],
            startDate: new Date(monthAgo),
            endDate: new Date(now),
            shortCode: null,
            keyword: null
        };

        this.fetchConversations = this.fetchConversations.bind(this);
        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleShortCodeChange = this.handleShortCodeChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
    };
    /* Event */
    componentDidMount() {
        this.fetchConversations();
        this.fetchCompanies();
        this.shortCodeList();
        this.keywordList();
    };
    /* Functions */
    fetchConversations(state) {
        apiClient.post("api/Report/UserRequestReport",
            {
                "businessIDs": this.state.businessID,
                "startDate": this.state.startDate,
                "endDate": this.state.endDate,
                "shortCodes": this.state.shortCode,
                "keywords": this.state.keyword,
                "lastRowId": 0,
                "sortColumn": state === null || state === undefined || state.sorted === undefined || state.sorted.length === 0 ? "CreatedDate" : state.sorted[0]['id'],
                "isDescending": state === null || state === undefined || state.sorted === undefined || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
                "perPage": state === null || state === undefined || state.pageSize === undefined ? 10 : state.pageSize,
            }).then(response => {
                // debugger;
                this.setState({
                    data: response.data["entities"],
                    pages: Math.ceil(parseInt(response.data["total"]) / state.pageSize),
                    loading: false,
                    total: response.data["total"],
                    tableState: state,
                    lastRowId: response.data["entities"].length === 0 ? 0 : response.data["entities"][response.data["entities"].length - 1].id
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
                this.setState({
                    loading: false
                });
            });
    };

    fetchCompanies() {
        let endpoint = "api/Business/List";
        apiClient.post(endpoint,
            {
                "searchValue": "",
                "lastRowId": 0,
                "sortColumn": "CreatedDate",
                "isDescending": true,
                "perPage": 1000
            }
        )
            .then(response => {
                this.setState({
                    companies: this.mapCompanies(response.data["entities"])
                });
            }).catch(error => {
                console.error(`Axios request failed: ${error}`);
            });
    };

    mapCompanies(companies) {
        let res = [];
        if (!companies || !companies.length)
            return res;

        for (let i = 0; i < companies.length; i++) {
            res.push({ value: companies[i]["id"], label: companies[i]["name"] });
        }
        return res;
    };

    shortCodeList() {
        apiClient.get('api/Business/ShortCodeList').then(response => {
            this.setState({
                shortcodes: response.data.map((o) => {
                    return { value: o.name, label: o.name };
                })
            })
        });
    }

    keywordList() {
        let endpoint = 'api/Business/KeywordList';
        apiClient.get(endpoint).then(response => {
            this.setState({
                keywords: response.data.map((o) => {
                    return { value: o.name, label: o.name };
                })
            })
        });
    }
    async handleDropDownChange(event) {
        let businessIDs = null;
        if (event != null && event.length > 0) {
            businessIDs = [];
            event.forEach(element => {
                businessIDs.push(element.value);
            });
        }
        await this.setState({
            businessID: businessIDs
        });

        this.fetchConversations(this.state.tableState);
    };

    async handleRangeChange(event) {
        if (event[0] === null || event[1] === null)
            return;

        let inputStart = event[0];
        inputStart.setHours(0);
        inputStart.setMinutes(0);
        inputStart.setSeconds(0);

        let inputEnd = event[1];
        inputEnd.setHours(12);
        inputEnd.setMinutes(59);
        inputEnd.setSeconds(59);

        await this.setState({
            startDate: inputStart,
            endDate: inputEnd
        });

        this.fetchConversations(this.state.tableState);
    };

    async handleChange(event) {
        await this.setState({
            [event.target.id]: event.target.value
        });

        this.fetchConversations(this.state.tableState);
    };

    async handleShortCodeChange(event) {
        let shortCodes = null;
        if (event != null && event.length > 0) {
            shortCodes = [];
            event.forEach(element => {
                shortCodes.push(element.value);
            });
        }

        await this.setState({
            shortCode: shortCodes
        });

        this.fetchConversations(this.state.tableState);
    }

    async handleKeywordChange(event) {
        let keywords = null;
        if (event != null && event.length > 0) {
            keywords = [];
            event.forEach(element => {
                keywords.push(element.value);
            });
        }

        await this.setState({
            keyword: keywords
        });

        this.fetchConversations(this.state.tableState);
    }

    render() {
        const { data, pages, loading } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Reports</h2>
                    </div>
                </div>
                <div className="reports-tools">
                    <div className="reports-tools-selection">
                        {this.props.isAdmin === undefined ?
                            <div className="ddCompany">
                                <React.Fragment>
                                    <Select
                                        name='companies'
                                        onChange={this.handleDropDownChange}
                                        options={this.state.companies}
                                        isClearable={true}
                                        placeholder="Choose a company"
                                        selectedOption={this.state.businessID}
                                        isMulti={true} />
                                </React.Fragment>
                            </div>
                            : ""
                        }
                        <div className="ddShortcode">
                            <Select
                                name='shortcodes'
                                onChange={this.handleShortCodeChange}
                                options={this.state.shortcodes}
                                isClearable={true}
                                placeholder="Choose a shortcode"
                                isMulti={true} />
                        </div>
                        <div className="ddKeyword">
                            <Select
                                name='keywords'
                                onChange={this.handleKeywordChange}
                                options={this.state.keywords}
                                isClearable={true}
                                placeholder="Choose a keyword"
                                isMulti={true} />
                        </div>
                    </div>
                    <div className="reports-tools-datepicker">
                        <label className="kt-report__range" style={{ marginRight: "8px" }}>Date range:</label>
                        <DateRangeInput
                            formatDate={date => date.toLocaleDateString()}
                            onChange={this.handleRangeChange}
                            parseDate={str => new Date(str)}
                            className="class='input-group"
                            value={[this.state.startDate, this.state.endDate]}
                        />
                    </div>
                </div>
                <div className="kt-portlet kt-report" style={{ borderRadius: "20px" }}>
                    <div className="kt-portlet__body user-table-container">
                        <ReactTable
                            columns={[
                                {
                                    Header: "Company",
                                    id: "BusinessName",
                                    accessor: d => d.businessName
                                },
                                {
                                    Header: "Keyword",
                                    id: "Text",
                                    accessor: d => d.text
                                },
                                {
                                    Header: "Short Code",
                                    id: "shortCode",
                                    accessor: d => d.shortCode
                                },
                                {
                                    Header: "Zip Code",
                                    id: "zipCode",
                                    accessor: d => d.zipCode
                                },
                                {
                                    Header: "Source",
                                    id: "Source",
                                    accessor: d => d.source,
                                    Cell: props => new AsYouType('US').input(props.value)
                                },
                                {
                                    Header: "Created Date",
                                    id: "createdDate",
                                    accessor: d => d.createdDate
                                }
                            ]}
                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                            data={data}
                            pages={pages} // Display the total number of pages
                            loading={loading} // Display the loading overlay when we need it
                            onFetchData={this.fetchConversations} // Request new data when things change
                            defaultPageSize={3}
                            className="-striped -highlight"
                        />
                        <Workbook filename="UserRequestReport.xlsx" element={<button className="btn bttn-success btn-width btn-shadow search-button" style={{ marginTop: '20px' }} >Export to excel</button>}>
                            <Workbook.Sheet data={data} name="Report">
                                <Workbook.Column label="Company" value="businessName" />
                                <Workbook.Column label="Source" value="source" />
                                <Workbook.Column label="Keyword" value="text" />
                                <Workbook.Column label="Zip Code" value="zipCode" />
                                <Workbook.Column label="Status" value="status" />
                                <Workbook.Column label="Short Code" value="shortCode" />
                                <Workbook.Column label="Created Date" value="createdDate" />
                            </Workbook.Sheet>
                        </Workbook>
                    </div>
                </div>
            </div>
        );
    };
}

export default UserRequestReport;