import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class TopMenu extends Component {

	constructor(props) {
		require('./topMenu.scss');
		super(props);
		this.state = {
			firstName: localStorage.getItem("profileFirstName"),
			lastName: localStorage.getItem("profileLastName"),
			admin: localStorage.getItem("roles").includes("Admin"),
            attorney: localStorage.getItem("roles").includes("Attorney"),
            staff: localStorage.getItem("roles").includes("Staff"),
		}
		this.signOut = this.signOut.bind(this);
	};

	signOut() {
		localStorage.setItem("access_token", '');
		localStorage.setItem("isAuthenticated", false);
		this.props.history.push('/login');
	}

	render() {
		return (
			<div>
				<div id="kt_header" className="kt-header kt-grid__item kt-topMenu">
					<div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
						<div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
							<ul className="kt-menu__nav "></ul>
						</div>
					</div>
					<div className="kt-header__topbar">
						<div className="kt-header__topbar-item kt-header__topbar-item--user">
							<div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px" aria-expanded="false">
								<div className="kt-header__topbar-user">
									<span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold noselect mr-3">{this.state.firstName.substring(0, 1)}{this.state.lastName.substring(0, 1)}</span>
									<span className="kt-header__topbar-username kt-hidden-mobile noselect">{this.state.firstName} {this.state.lastName}</span>
								</div>
							</div>
							<div id="top-menu-dropdown" className="user-dropdown dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md" x-placement="bottom-end" >
								<div className="kt-notification-extension"></div>
								<div className="kt-notification">
									<div className="kt-notification__item-container">
										<a href="/profile?tab=1" className="kt-notification__item">
											<div className="kt-notification__item-details">
												<div className="kt-notification__item-title">
													Profile Information
                                            	</div>
											</div>
										</a>
									</div>
									{this.state.attorney && <div className="kt-notification__item-container">
										<a href="/profile?tab=2" className="kt-notification__item">
											<div className="kt-notification__item-details">
												<div className="kt-notification__item-title">
													Business Information
                                            	</div>
											</div>
										</a>
									</div>}
									{this.state.attorney && <div className="kt-notification__item-container">
										<a href="/profile?tab=3" className="kt-notification__item">
											<div className="kt-notification__item-details">
												<div className="kt-notification__item-title">
													Payment Information
                                            	</div>
											</div>
										</a>
									</div>}
									{(this.state.attorney || this.state.staff) && <div className="kt-notification__item-container">
										<a href="/profile?tab=4" className="kt-notification__item">
											<div className="kt-notification__item-details">
												<div className="kt-notification__item-title">
													Phone Information
                                            	</div>
											</div>
										</a>
									</div>}
									<div className="top-menu-separator"></div>
									<div className="kt-notification__item-container">
										<button className="kt-notification__item top-menu-custom-button" onClick={this.signOut}>
											<div className="kt-notification__item-details">
												<div className="kt-notification__item-title">
													Sign Out
                                            	</div>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(TopMenu);