/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import apiClient from '../../components/Core/apiClient';
import { Typography, Row, Col, Checkbox, Select, Modal, Input, notification } from 'antd';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

class Answer extends Component {
    constructor(props) {
        super(props);
        require('./answer.scss');
        this.state = {
            node: this.props.node,
            id: this.props.node.id,
            sms: this.props.node.sms,
            action: this.props.node.action,
            saveAnswer: this.props.node.saveAnswer,
            dataTypeId: this.props.node.dataTypeId,
            parentId: this.props.node.parentId,

            modalVisible: false,
            deleteModalVisible: false,
        };
        this.updateAnswer = this.updateAnswer.bind(this);
        this.deleteAnswer = this.deleteAnswer.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.onChangeSaveAnswer = this.onChangeSaveAnswer.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    };

    componentDidMount() {
    }

    updateAnswer() {
        const contextThis = this;
        apiClient.post(`/api/Business/UpdateText/${this.state.id}`, {
            "sms": this.state.sms,
            "action": this.state.action,
            "saveAnswer": this.state.saveAnswer,
            "dataTypeId": this.state.dataTypeId
        }).then(response => {
            notification.success({
                message: 'text is updated successfully!'
            });
            contextThis.props.refreshTextNodes();
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
        this.setState({ modalVisible: false });
    }

    deleteAnswer() {
        const contextThis = this;
        apiClient.post(`/api/Business/DeleteText/${this.state.id}`).then(response => {
            notification.success({
                message: 'text is deleted successfully!'
            });
            contextThis.setState({
                deleteModalVisible: false
            });
            contextThis.props.refreshTextNodes();
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    addQuestion() {
        const contextThis = this;
        apiClient.post(`/api/Business/AddText/${this.props.packageId}`, {
            sms: "",
            mms: "",
            type: 0,
            action: 0,
            saveAnswer: false,
            dataTypeId: null,
            parentId: this.state.id,
            children: []
        }).then(response => {
            notification.success({
                message: 'text is added successfully!'
            });
            contextThis.props.refreshTextNodes();
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    onChangeSaveAnswer(e) {
        this.setState({
            saveAnswer: e.target.checked,
        });
    }

    handleCancel() {
        this.setState({
            modalVisible: false
        });
        this.props.refreshTextNodes();
    }

    render() {
        return (
            <div className="answer-item">
                <Row gutter={24} onClick={() => this.setState({ modalVisible: true })}>
                    <Col span={24}>
                        <Text>
                            {this.state.sms === '' || !this.state.sms ?
                                <Text style={{ color: '#fff' }}>&nbsp;</Text>
                                : <Text>{this.state.sms}</Text>}
                        </Text>
                    </Col>
                </Row>
                <Modal
                    title="Answer"
                    visible={this.state.modalVisible}
                    onOk={this.updateAnswer}
                    okText="Save changes"
                    onCancel={() => this.handleCancel()}
                >
                    <Row gutter={24} style={{
                        marginBottom: '1rem'
                    }}>
                        <Col span={8}>SMS: </Col>
                        <Col span={16}>
                            <TextArea
                                autoSize
                                placeholder="SMS message"
                                defaultValue={this.state.sms}
                                style={{ width: '100%', minWidth: '300px' }}
                                onChange={(e) => this.setState({ sms: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} style={{
                        marginBottom: '1rem'
                    }}>
                        <Col span={8}>Save Answer: </Col>
                        <Col span={16}>
                            <Checkbox
                                onChange={this.onChangeSaveAnswer}
                                checked={this.state.saveAnswer}
                            />
                        </Col>
                    </Row>
                    {this.state.saveAnswer ?
                        <Row gutter={24} style={{
                            marginBottom: '1rem'
                        }}>
                            <Col span={8}>Select a data type </Col>
                            <Col span={16}>
                                <Select
                                    showSearch
                                    style={{ width: 200 }}
                                    placeholder="Select..."
                                    optionFilterProp="children"
                                    onChange={(e) => this.setState({ dataTypeId: e })}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={this.state.dataTypeId}
                                >
                                    {this.props.datatypes ?
                                        this.props.datatypes.map(element => {
                                            return <Option value={element.id} key={element.id}>{element.name}</Option>
                                        })
                                        : ""
                                    }
                                </Select>
                            </Col>
                        </Row> : ""
                    }
                    <Row gutter={24} style={{
                        marginBottom: '1rem'
                    }}>
                        <Col span={8}>Select an action </Col>
                        <Col>
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select..."
                                optionFilterProp="children"
                                onChange={(e) => this.setState({ action: e })}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                defaultValue={this.state.action}
                            >
                                <Option value={0} key={0}>None</Option>
                                <Option value={1} key={1}>Reply</Option>
                                <Option value={2} key={2}>Call</Option>
                            </Select>
                        </Col>
                    </Row>
                </Modal>
                <div className={this.state.action === 1 && !this.props.hasChild ? 'package-tool' : 'package-tool no-child'} >
                    {this.props.hasChild ? ""
                        :
                        this.state.action === 1 ?
                            <span>
                                <a href={null} className="package-tool-link" onClick={this.addQuestion} title="add question">
                                    <PlusCircleOutlined />
                                </a>
                            </span> : ""
                    }
                    <span>
                        <a href={null} className="package-tool-link" onClick={() => this.setState({ deleteModalVisible: true })} title="delete answer">
                            <CloseCircleOutlined />
                        </a>
                        <Modal
                            title="Delete answer"
                            visible={this.state.deleteModalVisible}
                            onOk={this.deleteAnswer}
                            okText="Yes"
                            onCancel={() => this.setState({ deleteModalVisible: false })}
                        >
                            Are you sure to delete this answer?
                        </Modal>
                    </span>
                </div>
            </div>
        );
    }
}

export default Answer;