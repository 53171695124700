import React, { Component } from "react";
import FormInput from "../../components/Form/FormInput";
import apiClient from '../../components/Core/apiClient';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Swal from "sweetalert2";
import InputMask from 'react-input-mask';

class BusinessEdit extends Component {
    constructor(props) {
        super(props);
        require('react-notifications/lib/notifications.css');
        require('./business.scss');
        this.state = {
            name: '',
            phoneNumber: '',
            callNumber: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            isDefault: null,
            defaultSMS: '',
            defaultMMS: '',
            id: this.props.match.params.id,
            // errors: ''
            // TODO : Error messages
            errorBusinessName: false,
            errorBusinessPhoneNumber: false,
            errorBusinessAddress: false,
            errorBusinessCity: false,
            errorBusinessState: false,
            errorBusinessZipCode: false,
            errorBusinessSMS: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchEntity = this.fetchEntity.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        // TODO : Validation
        this.validateForm = this.validateForm.bind(this);
    };

    componentDidMount() {
        Swal.fire({
            title: 'Processing',
            html: 'Loading business information',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });
        this.fetchEntity();
        Swal.close();
    }

    async fetchEntity() {
        let endpoint = "api/Business/" + this.state.id;
        await apiClient.get(endpoint
        ).then(async response => {
            await this.setState({
                name: response.data['name'],
                phoneNumber: response.data['phoneNumber'],
                callNumber: response.data['callNumber'],
                address1: response.data['address1'],
                address2: response.data['address2'],
                city: response.data['city'],
                state: response.data['state'],
                zip: response.data['zip'],
                isDefault: response.data['isDefault'],
                defaultSMS: response.data['defaultSMS'],
                defaultMMS: response.data['defaultMMS']
            });
        }).catch(error => {
            console.error(`Axios request failed: ${error}`);
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        // TODO : Business validation is here
        // Business name
        if (name === 'name') {
            this.setState({ errorBusinessName: value === "" });
        }
        // PhoneNumber
        if (name === 'phoneNumber') {
            const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            const result = pattern.test(value);
            this.setState({ errorBusinessPhoneNumber: !result });
        }
        // Business Address
        if (name === 'address1') {
            this.setState({ errorBusinessAddress: value === "" });
        }
        // Business City
        if (name === 'city') {
            this.setState({ errorBusinessCity: value === "" });
        }
        // Business State
        if (name === 'state') {
            this.setState({ errorBusinessState: value === "" });
        }
        // Business ZipCode
        if (name === 'zip') {
            this.setState({ errorBusinessZipCode: value === "" });
        }
        // Business Default SMS
        if (name === 'defaultSMS') {
            this.setState({ errorBusinessSMS: value === "" });
        }
    }
    validateForm() {
        if (this.state.name.length === 0)
            this.setState({ errorBusinessName: true });
        if (this.state.phoneNumber.length === 0)
            this.setState({ errorBusinessPhoneNumber: true });
        if (!this.state.address1)
            this.setState({ errorBusinessAddress: true });
        if (!this.state.city)
            this.setState({ errorBusinessCity: true });
        if (!this.state.state)
            this.setState({ errorBusinessState: true });
        if (!this.state.zip)
            this.setState({ errorBusinessZipCode: true });
        if (!this.state.defaultSMS)
            this.setState({ errorBusinessSMS: true });
    }

    handleChecked() {
        this.setState({ isDefault: !this.state.isDefault });
    }

    async handleSubmit(event) {
        await this.validateForm();
        if (
            this.state.errorBusinessName ||
            this.state.errorBusinessPhoneNumber ||
            this.state.errorBusinessAddress ||
            this.state.errorBusinessCity ||
            this.state.errorBusinessState ||
            this.state.errorBusinessZipCode ||
            this.state.errorBusinessSMS
        )
            return false;
        Swal.fire({
            text: "Are you sure that you want to update information of this business?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#666FE8',
            cancelButtonColor: '#c9d1d8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Processing',
                    html: 'We are processing your request...',
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                });
                let endpoint = "api/Business/Update";
                apiClient.post(endpoint, {
                    name: this.state.name,
                    phoneNumber: this.state.phoneNumber,
                    callNumber: this.state.callNumber,
                    address1: this.state.address1,
                    address2: this.state.address2,
                    city: this.state.city,
                    state: this.state.state,
                    zip: this.state.zip,
                    isDefault: this.state.isDefault,
                    id: this.state.id,
                    defaultSMS: this.state.defaultSMS,
                    defaultMMS: this.state.defaultMMS
                }).then(response => {
                    NotificationManager.success('Business has been successfully updated!', 'Success');
                    Swal.close();
                }).catch(error => {
                    let errors = "";
                    if (error.response.data.errors[""] !== undefined) {
                        errors += error.response.data.errors[""][0];
                    }
                    if (error.response.data.errors["Name"] !== undefined) {
                        errors += error.response.data.errors["Name"][0];
                    }
                    if (error.response.data.errors["PhoneNumber"] !== undefined) {
                        errors += error.response.data.errors["PhoneNumber"][0];
                    }
                    if (error.response.data.errors["CallNumber"] !== undefined) {
                        errors += error.response.data.errors["CallNumber"][0];
                    }
                    if (error.response.data.errors["Address1"] !== undefined) {
                        errors += error.response.data.errors["Address1"][0];
                    }

                    if (error.response.data.errors["Address2"] !== undefined) {
                        errors += error.response.data.errors["Address2"][0];
                    }

                    if (error.response.data.errors["City"] !== undefined) {
                        errors += error.response.data.errors["City"][0];
                    }

                    if (error.response.data.errors["State"] !== undefined) {
                        errors += error.response.data.errors["State"][0];
                    }

                    if (error.response.data.errors["Zip"] !== undefined) {
                        errors += error.response.data.errors["Zip"][0];
                    }

                    if (error.response.data.errors["IsDefault"] !== undefined) {
                        errors += error.response.data.errors["IsDefault"][0];
                    }

                    this.setState({
                        errors: errors
                    });
                    NotificationManager.error(`${error}`, 'Error');
                    console.error(`Axios request failed: ${error}`);
                    Swal.close();
                });
            }
        })

    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="kt-portlet tl-black">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title font-weight-bold">Edit business details</h3>
                                </div>
                            </div>
                            <form className="kt-form">
                                <div className="kt-portlet__body">
                                    <div className="form-group form-group-last">
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormInput value={this.state.name} onChange={this.handleChange} type="text" name="name" required={true} label="Business Name" className="form-control" />
                                            {this.state.errorBusinessName ? <div className="error">Required</div> : ''}
                                        </div>
                                        <div className="col-md-3">
                                            <label>Phone Number *</label>
                                            <InputMask value={this.state.phoneNumber} onChange={this.handleChange} name="phoneNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                                            {this.state.errorBusinessPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                                        </div>
                                        <div className="col-md-3">
                                            <label>Call Number</label>
                                            <InputMask value={this.state.callNumber} onChange={this.handleChange} name="callNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormInput value={this.state.address1} onChange={this.handleChange} type="text" name="address1" required={true} label="Business Address" className="form-control" />
                                            {this.state.errorBusinessAddress ? <div className="error">Required</div> : ''}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInput value={this.state.address2} onChange={this.handleChange} type="text" name="address2" required={false} label="Suite" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormInput value={this.state.city} onChange={this.handleChange} type="text" name="city" required={true} label="City " className="form-control" />
                                            {this.state.errorBusinessCity ? <div className="error">Required</div> : ''}
                                        </div>
                                        <div className="col-md-3">
                                            <FormInput value={this.state.state} onChange={this.handleChange} type="text" name="state" required={true} label="State" className="form-control" />
                                            {this.state.errorBusinessState ? <div className="error">Required</div> : ''}
                                        </div>
                                        <div className="col-md-3">
                                            <FormInput value={this.state.zip} onChange={this.handleChange} type="text" name="zip" required={true} label="Zip Code" className="form-control" />
                                            {this.state.errorBusinessZipCode ? <div className="error">Required</div> : ''}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label htmlFor="city"> Default SMS </label>
                                            <textarea onChange={this.handleChange} name="defaultSMS" required={true} value={this.state.defaultSMS} className="form-control" />
                                            {this.state.errorBusinessSMS ? <div className="error">Required</div> : ''}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="city"> Default MMS </label>
                                            <textarea onChange={this.handleChange} name="defaultMMS" required={false} value={this.state.defaultMMS} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>Is Default</label>
                                            <div className="kt-checkbox-list">
                                                <label className="kt-checkbox kt-checkbox--bold">
                                                    {this.state.isDefault === true ?
                                                        <input onChange={this.handleChecked} name="isDefault" type="checkbox" checked="checked" />
                                                        :
                                                        <input onChange={this.handleChecked} name="isDefault" type="checkbox" />
                                                    } Checked
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <button type="button" onClick={this.handleSubmit} className="btn bttn-success btn-width btn-shadow" style={{ marginRight: "25px" }}>Save</button>
                                        <a href="/admin/businesses" className="btn bg-light-grey  btn-width btn-shadow">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </div>
        );
    }
}

export default BusinessEdit;